<template>
  <div class="control_wrapper">
    <Direction v-if="btnShow" @btnsound="btnsoundPlay" />

    <!-- 开火 -->
    <div
      class="fire"
      @touchstart="handleFireStartBtn($event)"
      @touchend="handleFireEndBtn($event)"
    >
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="!fire.type && !fire.clickStatu"
        :src="btnUI.fireUI.inactive"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="!fire.type && fire.clickStatu"
        :src="btnUI.fireUI.active"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="fire.type && !fire.clickStatu"
        :src="rapid_fire"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="fire.type && fire.clickStatu"
        :src="rapid_fire_active"
      />
    </div>
    <!-- 自动 -->
    <div
      class="auto"
      :style="autoBtnLocation"
      @touchstart="handleAutoBtn($event)"
      @touchend="handleAutoBtn($event)"
    >
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        :show-loading="false"
        position="center"
        v-show="!autoStatu"
        :src="btnUI.autoUI.inactive"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="autoStatu"
        :src="btnUI.autoUI.active"
      />
      <van-image
        class="auto_ing_img"
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="autoStatu"
        :src="btnUI.autoUI.ing"
      />

      <div class="auto_time_wrapper" v-show="autoStatu">
        {{ autoFireCounDownTime }}s
      </div>
    </div>

    <!-- 切换 -->
    <div
      class="toggle_btn"
      @touchstart="handleToggleBtn($event)"
      @touchend="handleToggleBtn($event)"
      v-if="btnShow"
    >
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="fire.type && !toggleStatu"
        :src="btnUI.toggleUI.inactive"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="fire.type && toggleStatu"
        :src="btnUI.toggleUI.active"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="!fire.type && !toggleStatu"
        :src="rapid_fire"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="!fire.type && toggleStatu"
        :src="rapid_fire_active"
      />
    </div>

    <!-- 投币 -->
    <div
      class="upcoin_btn"
      @touchstart="handleUpCoinBtnStart($event)"
      @touchend="handleUpCoinBtnEnd($event)"
    >
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="!coinStatu"
        :src="btnUI.coinUI.inactive"
      />
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        position="center"
        :show-loading="false"
        v-show="coinStatu"
        :src="btnUI.coinUI.active"
      />

      <div class="hang_times" v-if="store.settlementResult.type == 'normal'">
        {{ counDownTime }} s
      </div>
      <div class="active_times" v-else>
        {{ activeTime }}
      </div>
    </div>

    <!-- 最后几秒倒计时 v-if="mxtNoOperationBackCount <= 6" -->
    <div class="count_down_time_wrapper">
      <div
        class="count_down_time_img"
        v-for="(
          count_down_time_item, count_down_time_index
        ) in count_down_time_arr"
      >
        <transition name="van-fade">
          <van-image
            v-show="counDownTime - 1 == count_down_time_index"
            width="100%"
            height="auto"
            fit="fill"
            position="center"
            :show-loading="false"
            block
            :src="count_down_time_item"
          />
        </transition>
      </div>
    </div>

    <van-popup
      v-model:show="select_auto_time_pup_show"
      style="background: none; max-width: 100vw"
      teleport="body"
    >
      <div class="select_auto_time_wrapper">
        <div class="rotate_box22">
          <div class="title"></div>

          <div class="auto_time_value_wrapper">
            <span>发炮时间</span>
            <span>{{ select_auto_time_minute }}分钟</span>
          </div>

          <div class="slide_block"></div>

          <div class="_text2">点击发炮键即可取消自动发炮</div>

          <div class="btns">
            <div class="btn1" @click="select_auto_time_pup_show_toggle(false)">
              取消
            </div>
            <div class="btn2" @click="start_auto_fire">开启</div>
          </div>
        </div>

        <div class="_slide_b">
          <van-slider
            vertical
            bar-height="12px"
            v-model="select_auto_time_minute"
            min="1"
            max="10"
            active-color="#6843FF"
            inactive-color="#C8C8FD"
          >
            <template #button>
              <div class="custom_button"></div>
            </template>
          </van-slider>
        </div>
      </div>
    </van-popup>

    <!-- <UpcoinBtn
      @btnsound="btnsoundPlay"
      @upcoin_num="upcoinClick"
      :forbid="store.settlementResult.type != 'normal'"
    /> -->
    <!-- 加强键 -->
    <PlusBtn @btnsound="btnsoundPlay" />

    <!-- 投币选择 -->
    <van-popup v-model:show="upcoinNumShow" style="background: none">
      <div class="upcoin_num_wrapper">
        <div class="upcoin_num_title">
          <p>选择投币次数</p>
        </div>
        <div class="upcoin_num_list">
          <div
            class="upcoin_num_item"
            v-for="upcoinItem in upcoinNumList"
            @click="upcoinRequsult(upcoinItem.num)"
          >
            <div class="upcoin_info">
              <span class="gold_icon"></span>
              <span class="gold_num">{{ upcoinItem.gold_num }}</span>
            </div>
            <div class="upcoin_num">投币&nbsp;{{ upcoinItem.num }}&nbsp;次</div>
          </div>
        </div>
        <div
          class="upcoin_num_tips"
          @click="
            () => {
              upcoinNumShow = false;
            }
          "
        >
          取消投币
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import Direction from "@/components/Direction.vue";
// import UpcoinBtn from "@/components/UpcoinBtn.vue";
import PlusBtn from "@/components/PlusBtn.vue";
import { sendInstructions } from "@/api/sendData";
import { Howl, Howler } from "howler";
import btnClick from "@/assets/audios/btn_click.mp3";
import { useCountDown } from "@vant/use";
import { useStore } from "@/store/store";
import { controlRequest } from "@/api";
import { reactive, ref, onMounted, computed, onBeforeUnmount } from "vue";
import { showToast } from "vant";

const store = useStore();

const fire_inactive = store.imgBaseUrl + "/control/fire.png";
const fire_active = store.imgBaseUrl + "/control/fire_active.png";

const rapid_fire = store.imgBaseUrl + "/control/rapid_fire.png";
const rapid_fire_active = store.imgBaseUrl + "/control/rapid_fire_active.png";

const auto_img = store.imgBaseUrl + "/control/auto.png";
const auto_active_img = store.imgBaseUrl + "/control/auto_active.png";
const auto_ing_img = store.imgBaseUrl + "/control/auto_ing.png";

const toggle_1 = store.imgBaseUrl + "/control/toggle_1.png";
const toggle_1_active = store.imgBaseUrl + "/control/toggle_1_active.png";

const coin_btn = store.imgBaseUrl + "/control/coin_btn.png";
const coin_btn_active = store.imgBaseUrl + "/control/coin_btn_active.png";

const bbl_fire_btn = store.imgBaseUrl + "/control/bbl_fire_btn.png";
const bbl_fire_btn_active =
  store.imgBaseUrl + "/control/bbl_fire_btn_active.png";

const bbl_auto_btn = store.imgBaseUrl + "/control/bbl_auto.png";
const bbl_auto_active = store.imgBaseUrl + "/control/bbl_auto_active.png";

const bbl_fire_btn_ing = store.imgBaseUrl + "/control/bbl_auto_ing.png";

const bbl_coin_btn = store.imgBaseUrl + "/control/bbl_coin_btn.png";
const bbl_coin_btn_active =
  store.imgBaseUrl + "/control/bbl_coin_btn_active.png";

const count_down_time_1 = store.imgBaseUrl + "/mxt/count_down_time_1.png";
const count_down_time_2 = store.imgBaseUrl + "/mxt/count_down_time_2.png";
const count_down_time_3 = store.imgBaseUrl + "/mxt/count_down_time_3.png";
const count_down_time_4 = store.imgBaseUrl + "/mxt/count_down_time_4.png";
const count_down_time_5 = store.imgBaseUrl + "/mxt/count_down_time_5.png";
const count_down_time_6 = store.imgBaseUrl + "/mxt/count_down_time_6.png";

const count_down_time_arr = [
  count_down_time_1,
  count_down_time_2,
  count_down_time_3,
  count_down_time_4,
  count_down_time_5,
  count_down_time_6,
];

const emits = defineEmits(["count-down-end", "fire-click"]);

//#region 区分不同游戏的按键UI
const btnUI = computed(() => {
  const uis = {
    fireUI: {
      active: fire_active,
      inactive: fire_inactive,
    },
    autoUI: {
      active: auto_active_img,
      inactive: auto_img,
      ing: auto_ing_img,
    },
    toggleUI: {
      active: toggle_1_active,
      inactive: toggle_1,
    },
    coinUI: {
      active: coin_btn_active,
      inactive: coin_btn,
    },
  };

  if (store.current_machine.product_name == "bbl") {
    uis.fireUI.active = bbl_fire_btn_active;
    uis.fireUI.inactive = bbl_fire_btn;

    uis.autoUI.active = bbl_auto_active;
    uis.autoUI.inactive = bbl_auto_btn;
    uis.autoUI.ing = bbl_fire_btn_ing;

    uis.coinUI.inactive = bbl_coin_btn;
    uis.coinUI.active = bbl_coin_btn_active;
    uis.coinUI.ing = bbl_coin_btn_active;
  }

  return uis;
});

const btnShow = computed(() => {
  if (store.current_machine.product_name == "bbl") return false;
  return true;
});

//#endregion

//#region 声音
const btnClickSound = new Howl({
  src: [btnClick],
  autoplay: false,
  loop: false,
});

const btnsoundPlay = () => {
  btnClickSound.play();

  if (store.current_machine.child_name == "go") {
    noUpcoinCounDown.reset();
    noUpcoinCounDown.start();
  }
};

onBeforeUnmount(() => {
  btnClickSound.unload();
});
//#endregion

//#region 开火
const fire = reactive({
  type: false, // false 普通 / true 快速
  clickStatu: false,
});
const fireParams = {
  device: store.current_device.device_name,
  product_id: store.current_machine.product_id,
  channel: 5,
  type: 2,
  times: 1,
  width: 100,
  interval: 150,
  uid: parseInt(store.userInfo.uid),
};

let startTime = 0; // 按下时间
let continueTimer = null; // 持续定时器
let continueTime = 300; // 定时器时间

const handleFireStartBtn = (evt) => {
  evt && evt.preventDefault();
  evt && evt.stopPropagation();
  fire.clickStatu = true;

  autoStatu.value && handleAutoBtn({ type: "touchstart" });
  store.settlementResult.type == "normal" && noUpcoinCounDown.reset();
  startTime = Date.now();
  continueTimer != null && clearTimeout(continueTimer);
  continueTimer = setTimeout(() => {
    continueTimer = null;
    fireParams.times = 0;
    fireParams.type = 2;
    controlRequest(fireParams);
  }, continueTime);
};

const handleFireEndBtn = (evt) => {
  evt && evt.preventDefault();
  evt && evt.stopPropagation();
  fire.clickStatu = false;

  fireParams.times = 1;
  continueTimer != null && clearTimeout(continueTimer);
  if (Date.now() - startTime < continueTime) fireParams.type = 2;
  else fireParams.type = 0;

  store.current_machine.product_name == "bbl" && emits("fire-click");

  store.settlementResult.type == "normal" && noUpcoinCounDown.start();

  btnsoundPlay();
  controlRequest(fireParams);
};

//#endregion

//#region 自动
const select_auto_time_pup_show = ref(false);
const select_auto_time_pup_show_toggle = (statu = false) => {
  select_auto_time_pup_show.value = statu;
};

const select_auto_time_minute = ref(1);
const autoStatu = ref(false);
const autoBtnLocation = reactive({
  right: "140px",
  bottom: "44px",
});

if (store.current_machine.product_name == "bbl") {
  autoBtnLocation.bottom = "40px";
  autoBtnLocation.right = "10px";
}

const autoLockLevel = 4;
const handleAutoBtn = (evt) => {
  evt && evt.preventDefault && evt.preventDefault();
  evt && evt.stopPropagation && evt.stopPropagation();
  const evtType = evt.type;

  if (
    store.userInfo.recharge_level < autoLockLevel &&
    evtType === "touchstart"
  ) {
    showToast({
      type: "text",
      message: `仅限${
        store.gradLevels[autoLockLevel - 1].level_text
      }及以上等级用户可使用该功能`,
      teleport: "#rotate_box",
    });

    return;
  }

  if (evtType === "touchstart") {
    btnsoundPlay();
    if (autoStatu.value) {
      autoFireRequest(false);
    } else {
      select_auto_time_pup_show_toggle(true);
    }
  }
  // if (evtType === "touchend" && autoStatu.value) {
  // }
};

const autoFireRequest = (statu = true) => {
  if (statu) {
    autoStatu.value = true;
    fireParams.times = 0;
    fireParams.type = 2;
    fire.clickStatu = true;
    store.settlementResult.type == "normal" && noUpcoinCounDown.reset();
  } else {
    autoStatu.value = false;
    fire.clickStatu = false;
    fireParams.times = 1;
    fireParams.type = 0;
    if (store.settlementResult.type == "normal") {
      noUpcoinCounDown.reset();
      noUpcoinCounDown.start();
    }

    autoFireCounDown.pause();
  }

  controlRequest(fireParams);
};

const autoFireCounDown = useCountDown({
  time: 6e5,
  onFinish() {
    autoFireRequest(false);
  },
});

const start_auto_fire = () => {
  select_auto_time_pup_show_toggle(false);

  autoFireCounDown.reset(select_auto_time_minute.value * 6e4);
  autoFireCounDown.start();
  autoFireRequest(true);
};

const autoFireCounDownTime = computed(() => {
  return (
    autoFireCounDown.current.value.minutes * 60 +
    autoFireCounDown.current.value.seconds
  );
});

//#endregion

//#region 快慢切换
const toggleStatu = ref(false);
const handleToggleBtn = (evt) => {
  evt && evt.preventDefault();
  evt && evt.stopPropagation();
  const evtType = evt.type;

  if (evtType === "touchstart") {
    toggleStatu.value = true;
  }
  if (evtType === "touchend") {
    toggleStatu.value = false;
    btnsoundPlay();
    autoStatu.value && handleAutoBtn({ type: "touchstart" });

    if (fire.type) {
      fire.type = false;
      fireParams.width = 100;
      fireParams.interval = 150;
    } else {
      fire.type = true;
      fireParams.width = 60;
      fireParams.interval = 60;
    }
  }
};
//#endregion

//#region 投币
const upcoinNumList = [
  {
    gold_num: Number(store.current_machine.up_coin) * 1,
    num: 1,
  },
  {
    gold_num: Number(store.current_machine.up_coin) * 5,
    num: 5,
  },
  {
    gold_num: Number(store.current_machine.up_coin) * 10,
    num: 10,
  },
];
const upcoinNumShow = ref(false);
const upcoinRequsult = (num) => {
  upcoinNumShow.value = false;
  if (
    Number(store.userInfo.gold) >=
    Number(store.current_machine.up_coin) * num
  ) {
    //  投币
    const params = {
      msg_type: "up_coin",
      machine_index: store.current_machine.machine_index,
      game_name: store.current_machine.product_name,
      device_name: store.current_device.device_name,
      up_num: num,
    };

    if (store.settlementResult.type == "normal" && !autoStatu.value) {
      noUpcoinCounDown.reset();
      noUpcoinCounDown.start();
    }
    sendInstructions(params);
  } else {
    showToast({
      type: "fail",
      message: "币量不足,请充值。",
      teleport: "#rotate_box",
      onClose() {
        store.payPupToggle(true);
      },
    });
  }
};

const coinStatu = ref(false);

const handleUpCoinBtnStart = (evt) => {
  if (store.settlementResult.type !== "normal") return;
  evt.preventDefault && evt.preventDefault();
  evt.stopPropagation && evt.stopPropagation();

  coinStatu.value = true;
};
const handleUpCoinBtnEnd = (evt) => {
  if (store.settlementResult.type !== "normal") return;
  evt.preventDefault && evt.preventDefault();
  evt.stopPropagation && evt.stopPropagation();
  coinStatu.value = false;

  upcoinNumShow.value = true;
};

const noUpcoinCounDown = useCountDown({
  time: store.current_machine.product_name == "bbl" ? 48e4 : 9e4,
  onFinish() {
    emits("count-down-end");
  },
});

const activeTime = computed(() => {
  if (store.sync_player_free_match_time > 0) {
    const time = Number(store.sync_player_free_match_time);
    if (time == 3 || time == undefined) emits("count-down-end");

    let minute = parseInt(time / 60);
    minute = minute < 10 ? "0" + minute : minute;
    let second = time % 60;
    second = second < 10 ? "0" + second : second;

    return `${minute}:${second}`;
  } else return "00:00";
});

const counDownTime = computed(() => {
  return (
    noUpcoinCounDown.current.value.minutes * 60 +
    noUpcoinCounDown.current.value.seconds
  );
});

onMounted(() => {
  if (store.settlementResult.type == "normal") {
    noUpcoinCounDown.start();
  }
});

//#endregion

defineExpose({ autoStatu });
</script>

<style lang="scss" scoped>
.control_wrapper {
  position: absolute;
  z-index: 4;
  height: 100%;
  width: 100%;
}

.fire {
  position: absolute;
  right: 60px;
  bottom: 60px;
  width: 80px;
  height: 80px;
}

// 倒计时
.count_down_time_wrapper {
  position: absolute;
  left: 50%;
  top: 42%;
  width: 190px;
  height: 190px;
  transform: translate(-50%, -50%);
  z-index: 3;
  .count_down_time_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.auto {
  position: absolute;

  width: 48px;
  height: 48px;
  // background-color: orange;

  .auto_ing_img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    animation: firerAnimt 1s infinite linear;
  }

  .auto_time_wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 2;
    background-color: rgba($color: #000000, $alpha: 0.6);
    font-size: 14px;
    line-height: 48px;
    color: #fff;
    text-align: center;
    font-weight: 800;
  }
}

@keyframes firerAnimt {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes firerAnimt

/* Safari 与 Chrome */ {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.toggle_btn {
  position: absolute;
  right: 10px;
  bottom: 76px;
  width: 48px;
  height: 48px;
}

// 投币
.upcoin_btn {
  position: absolute;
  right: 34px;
  top: 170px;
  width: 48px;
  height: 48px;
  z-index: 4;
  opacity: 0.8;

  .hang_times {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background-color: rgba($color: #000000, $alpha: 0.6);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-weight: 800;
    border-radius: 10px;
  }

  .active_times {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    border-radius: 50%;
    text-align: center;
    line-height: 48px;
    font-size: 12px;
    font-weight: bold;
  }
}

.upcoin_num_wrapper {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // transform: rotate(90deg);

  .upcoin_num_title {
    font-size: 20px;
    font-weight: bold;
  }

  .upcoin_num_list {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .upcoin_num_item {
      width: 80px;
      height: 80px;
      background: linear-gradient(180deg, #d7bdfa 0%, #9553ff 100%);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .upcoin_info {
        display: flex;
        justify-content: center;
        align-items: center;
        .gold_icon {
          width: 20px;
          height: 20px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 4px;
        }
        .gold_num {
          font-size: 14px;
          height: 20px;
        }
      }

      .upcoin_num {
        text-align: center;
        width: 100%;
        height: 20px;
      }
    }
  }

  .upcoin_num_tips {
    margin-top: 40px;
    font-size: 16px;
    color: #fff;
  }
}

.select_auto_time_wrapper {
  position: relative;
  width: 375px;
  height: 375px;

  .rotate_box22 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: rotate(90deg) translate(-50%, -50%);
    transform-origin: left top;
    width: 232px;
    height: 168px;
    padding: 40px 14px 0 14px;
    background: linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);
    border-radius: 20px;
  }

  .title {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    background-image: url("@/assets/images/text_set.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 32px;
    height: 24px;
  }

  ._slide_b {
    position: absolute;
    left: 4.96rem;
    top: 2.58rem;
    // background-color: skyblue;

    width: 30px;
    height: 180px;
    display: flex;
    justify-content: center;

    .custom_button {
      width: 26px;
      height: 26px;
      background-image: url("@/assets/images/control/slide_block_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .slide_block {
    width: 100%;
    height: 30px;
  }

  .auto_time_value_wrapper {
    font-size: 12px;
    color: #6843ff;
    display: flex;
    flex-wrap: bold;
    justify-content: space-between;
  }

  ._text2 {
    width: 100%;
    font-size: 10px;
    color: #9c9c9c;
    text-align: center;
  }

  .btns {
    margin-top: 20px;
    padding: 0 10px;
    width: 100%;
    height: 30px;
    display: flex;

    justify-content: space-between;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    .btn1 {
      width: 48%;
      height: 30px;
      background: linear-gradient(90deg, #81c3ff 0%, #5034ff 100%);
      opacity: 0.3;
      border-radius: 16px;
      font-weight: 800;
    }

    .btn2 {
      width: 48%;
      height: 30px;
      background: linear-gradient(90deg, #ed81ff 0%, #5034ff 100%);
      box-shadow: inset 0 -5 13px #bed9ff;
      border-radius: 16px;
      font-weight: 800;
    }
  }
}
</style>
