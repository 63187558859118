import { useStore } from "@/store/store";
import {
  initGameData,
  initTianTiData,
  initCollectCardListData,
  initPayPupData,
  initSignData,
  formatTimestamp,
  decodeBase64String,
  uidStringLengthFixed,
  initRecordData,
  initGameViewTaskData,
  initCardBagData,
} from "@/utils/utils";
import { closeToast, showToast } from "vant";
import Decimal from "decimal.js";
import { mockBackResult } from "@/utils/mockResult";
// 指令key
const scheme = "shipin";
const isMock = false; // 数据模拟

// 指令发送
/**
 * 自定义协议指令请求
 * @param {object} params - 对象参数
 * @param {number} latencyTime - 延时
 * @param {boolean} isLoding - loding状态
 * @return {void}
 */
export const sendInstructions = (params, latencyTime = 50) => {
  // 注意!：使用Promise 优先级过高可能会导致数据丢失  退而其次 使用 setTimeout
  let instruct = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  // 兼容
  setTimeout(() => {
    document.location = `${scheme}://${instruct}`;
  }, latencyTime);

  // 数据模拟
  if (isMock) {
    const noResultTypes = [
      "net_close",
      "get_player_common",
      "get_machine_info",
    ];

    const msg_type = noResultTypes.includes(params.msg_type)
      ? params.msg_type
      : params.msg_type + "_result";

    mockBackResult(msg_type, 1000);
  }
};
export const asyncSendInstructions = (params, timeout = 1e4) => {
  const instruct = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  setTimeout(() => {
    document.location = `${scheme}://${instruct}`;
  }, 0);

  const noResultTypes = ["net_close", "get_player_common", "get_machine_info"];

  const _Msg_type = noResultTypes.includes(params.msg_type)
    ? params.msg_type
    : params.msg_type + "_result";

  isMock && mockBackResult(_Msg_type, 1000);

  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      if (window.setDataPromiseResolveMap.get(_Msg_type)) {
        window.setDataPromiseResolveMap.delete(_Msg_type);
      }
      reject(new Error("Timeout waiting for set_data"));
    }, timeout);
  });

  const handlePromise = new Promise((resolve, _) => {
    window.setDataPromiseResolveMap.set(_Msg_type, resolve);
  });
  return Promise.race([handlePromise, timeoutPromise]);
  // return handlePromise;
};

/**
 * 轮询游戏最新信息
 * @param {object} option - 对象参数
 * @param {string} obj.statu - 状态
 * @param {string} obj.game_name - 名字
 * @param {boolean} obj.instant - 立即触发
 */
export const pollingGameinfo = (option) => {
  let _params = {
    msg_type: "get_machine_info",
    game_name: option.game_name,
  };

  if (option.game_name == "msqy_line" || option.game_name == "vip_tbj") {
    _params = {
      msg_type: "get_machine_info_by_group",
      group: option.game_name,
    };
  }

  if (option.game_name == "duobao") {
    _params = {
      msg_type: "get_game_table_list",
      game_name: "duobao",
    };
  }

  if (option.instant) {
    window.getGameTimer != null && clearInterval(window.getGameTimer);
    sendInstructions(_params, 0);
  }

  if (option.statu == "start") {
    window.getGameTimer != null && clearInterval(window.getGameTimer);
    window.getGameTimer = setInterval(() => {
      sendInstructions(_params);
    }, 1000);
  } else {
    window.getGameTimer != null && clearInterval(window.getGameTimer);
    window.getGameTimer = null;
  }
};

// 断线;
const net_close = (store, info) => {
  pollingGameinfo({
    statu: "close",
  });
  store.$patch((state) => {
    state.serverStatus.net_close_statu = true;
  });
};

// 停服维护
const stop_service_result = (store, info) => {
  pollingGameinfo({
    statu: "close",
  });
  store.$patch((state) => {
    state.serverStatus.stop_service_statu = true;
  });
  showToast({
    type: "fail",
    message: "即将停服维护！",
    duration: 0,
    forbidClick: true,
  });
  // 如果当前正在游戏
  if (!store.gameIsWatch) {
    // 退分 params
    const params = {
      msg_type: "return_lottery",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
      device_name: store.current_device.device_name,
    };

    sendInstructions(params, 50);
    sendInstructions(params, 150);
    sendInstructions(params, 250);

    store.$patch((state) => {
      state.gameIsWatch = true;
    });
  }
};

// 用户信息
const get_player_common = (store, info) => {
  store.$patch((state) => {
    const userKeys = Object.keys(info);
    const userInfoKeys = Object.keys(state.userInfo);
    userKeys.forEach((key) => {
      let hasOwn = true;
      try {
        hasOwn = Object.hasOwn(state.userInfo, key);
      } catch (error) {
        hasOwn = userInfoKeys.includes(key);
      }

      if (hasOwn) {
        let value = info[key];

        // if (key == "headimgurl" && value != "") {
        //   // value = value + "?r=" + Math.random().toFixed(5);
        // } else

        if (key == "head_frame" && info.headimgurl == "") {
          key = "headimgurl";
        }

        if (key == "register_time") {
          // const fresh_time = Number(value) * 1000 + 604800000;
          const fresh_time = Number(value) * 1000 + 432000000;
          // console.log("usersssss_fresh_time", fresh_time);
          value = Date.now() < fresh_time;
        }

        key == "uid" && (value = uidStringLengthFixed(value));
        key == "player_name" && (value = decodeBase64String(value));
        state.userInfo[key] = value;
      }
    });

    // 渠道包
    if (
      Number(info.recommend) == 26704315 &&
      Number(info.recommend) == 13584902 &&
      !info.id_verification &&
      !info.is_gm
    )
      state.auth_user_show = true;
  });

  // 查看离线积分
  // if (info.add_lottery && Number(info.add_lottery) > 0) {
  //   sendInstructions({
  //     msg_type: "get_leave_lottery",
  //   });
  // }
};

// 游戏列表
const get_machine_info = (store, info) => {
  const keysN = Object.keys(info);
  if (keysN.length == 0) return;

  // console.log("info", info);

  const game_name = info[keysN[0]].product_name;
  store.$patch((state) => {
    const gameDataList = initGameData(info);

    state.gameDataList[game_name] = gameDataList;

    gameDataList.some((item) => {
      if (
        item.product_id == state.current_machine.product_id &&
        item.machine_index == state.current_machine.machine_index
      ) {
        state.current_machine = item;

        item.device_info.some((dev) => {
          if (
            dev.device_name == state.current_device.device_name &&
            dev.device_index == state.current_device.device_index
          ) {
            state.current_device.check_num = dev.check_num;
            return true;
          } else return false;
        });

        return true;
      } else return false;
    });
  });
};
// 退分
const return_lottery_result = (store, info) => {
  const noAddLotteryProductNames = [
    "mxt",
    "msqy",
    "msqy_line",
    "bbxc",
    "bjl",
    "bql",
    "bjwg",
    "hdcz",
  ];
  store.$patch((state) => {
    state.settlementResult.result_show = true;
    state.settlementResult.lottery = info.lottery;
    state.settlementResult.game_name = info.game_name;
    state.settlementResult.machine_index = info.machine_index;
    state.settlementResult.device_name = info.device_name;
    if (!noAddLotteryProductNames.includes(info.game_name)) {
      state.userInfo.lottery =
        Number(state.userInfo.lottery) + Number(info.lottery);
    }
  });
};
// 退分状态
const notify_mqtt_counting_result = (store, info) => {
  store.$patch((state) => {
    state.notify_mqtt_counting_result_key = Date.now();
  });
};

// 上分
const up_coin_result = (store, info) => {
  if (info.error === "ok") {
    store.$patch((state) => {
      state.await_up_coin_result = false;
      state.userInfo.gold = Number(state.userInfo.gold) - Number(info.dec_gold);
    });
  } else store.await_up_coin_result = false;
};

// 天梯
const get_tianti_rank_result = (store, info) => {
  store.$patch((state) => {
    const iniTtData = initTianTiData(info, state.userInfo);
    state.init_tianti_rank_data = iniTtData;
    state.userInfo.level = iniTtData.score.level;
    state.userInfo.dashi_card = iniTtData.dashi_card;
    state.userInfo.zongshi_card = iniTtData.zongshi_card;
  });
};

// 玩家收集
const get_player_collect_result = (store, info) => {
  store.$patch((state) => {
    const { initCollectPageData, collect_reward_list } =
      initCollectCardListData(info);
    state.initCollectPageData = initCollectPageData;
    state.collect_reward_list.mgc = collect_reward_list.mgc;
    state.collect_reward_list.bbl = collect_reward_list.bbl;
    state.collect_reward_list.mxt = collect_reward_list.mxt;
    state.collect_reward_list.msqy = collect_reward_list.msqy;
    state.collect_reward_list.hw3 = collect_reward_list.hw3;
  });
};
// 收集兑换
const exchange_collect_result = (store, info) => {
  store.$patch((state) => {
    if (info.error && info.error == "ok" && info.add_gold) {
      showToast({
        type: "success",
        message: "兑换成功!",
      });

      state.userInfo.gold = Number(state.userInfo.gold) + Number(info.add_gold);

      // 重新获取数据
      sendInstructions({
        msg_type: "get_player_collect",
      });
    } else {
      showToast({
        type: "fail",
        message: "兑换失败",
      });
    }
  });
};
// 南瓜榜
const get_nangua_rank_result = (store, info) => {
  store.$patch((state) => {
    state.pumpkinRankList = info;
  });
};
// 充值数据
const get_player_recharge_status_result = (store, info) => {
  closeToast();

  store.$patch((state) => {
    const {
      pinitData: pdata,
      giftData,
      gradLevels,
      cardTaskList,
    } = initPayPupData(info);

    pdata.day.unshift(pdata.day.pop());

    state.initPayPupData = pdata;
    state.userInfo.recharge_level = pdata.userPayInfo.level;
    state.giftData = giftData;
    state.gradLevels = gradLevels;

    state.cardTaskList = cardTaskList;
  });
};
// 彩劵兑换
const exchange_lottery_result = (store, info) => {
  store.$patch((state) => {
    if (info.error == "ok" && info.gold) {
      showToast({
        type: "success",
        message: `获得${info.gold}电玩币`,
      });
      state.userInfo.gold = Number(state.userInfo.gold) + Number(info.gold);

      state.userInfo.lottery = 0;
    } else {
      showToast({
        type: "fail",
        message: "兑换失败",
      });
    }
  });
};
// 充值反馈
const player_recharge_result = (store, info) => {
  closeToast();
  showToast({
    type: "success",
    message: `充值成功`,
    forbidClick: true,
    onClose() {
      store.$patch((state) => {
        info.tiaozhan &&
          (state.userInfo.tiaozhan_ticket =
            Number(state.userInfo.tiaozhan_ticket) + Number(info.tiaozhan));

        info.gold &&
          (state.userInfo.gold =
            Number(state.userInfo.gold) + Number(info.gold));

        info.level && (state.userInfo.recharge_level = Number(info.level));

        state.payPupStatu.show = false;
        sendInstructions({
          msg_type: "get_player_recharge_status",
        });

        sendInstructions({
          msg_type: "get_item",
        });
      });
    },
  });
};
// 取消充值
const player_recharge_cancel_result = (store, info) => {
  closeToast();
  showToast({
    type: "fail",
    message: "取消充值",
  });
};

// 獲取服務器時間 ;
const get_server_time_result = (store, info) => {
  store.$patch((state) => {
    state.server_time_data = formatTimestamp(
      info.server_time,
      "mm-dd hh:mi:ss"
    );
  });
};
// 返回馬戲團實時lottery
const tuibiji_return_lottery_result = (store, info) => {
  store.$patch((state) => {
    state.mxtHintDataList.push({
      lottery: info.lottery,
      key: Date.now(),
    });

    state.mxtHintDataNumberCount =
      state.mxtHintDataNumberCount + Number(info.lottery);

    state.userInfo.lottery =
      Number(state.userInfo.lottery) + Number(info.lottery);

    if (Number(info.lottery) >= 1000 && !state.mxtSuperGoloHint) {
      state.mxtSuperGoloHint = true;
      setTimeout(() => {
        state.mxtSuperGoloHint = false;
      }, 3000);
    }
  });
};

// 登录奖励
const get_login_reward_result = (store, info) => {
  const dd = initSignData(info);
  store.$patch((state) => {
    state.signInData = dd;

    // if (dd.isToday) {
    //   state.signShow = true;
    // }
  });
};

// 福利罐子
const get_player_weal_box_result = (store, info) => {
  store.$patch((state) => {
    state.wealBoxData = info;
  });
};
// 领取福利罐子
const exchange_player_weal_box_result = (store, info) => {
  if (info.error == "ok" || info.gold) {
    showToast({
      type: "success",
      message: "领取成功！",
      onClose: () => {
        sendInstructions({
          msg_type: "get_player_weal_box",
        });
      },
    });

    store.$patch((state) => {
      state.userInfo.gold = Number(state.userInfo.gold) + Number(info.gold);
    });
  }
};
// 福利罐子明细
const get_player_weal_box_log_result = (store, info) => {};

// 绑定邀请码;
const bind_invite_result = (store, info) => {
  if (info.error == "ok") {
    showToast({
      type: "success",
      message: "绑定成功！",
    });

    if (info.gold) {
      store.$patch((state) => {
        state.userInfo.gold = Number(state.userInfo.gold) + Number(info.gold);
      });
    }
  } else {
    showToast({
      type: "fail",
      message: info.error,
    });
  }
};
// 获取绑定奖励log
const get_invite_reward_log_result = (store, info) => {
  store.$patch((state) => {
    state.inviteLogList = info;
  });
};
// 绑定数据
const get_invite_info_result = (store, info) => {
  store.$patch((state) => {
    state.get_invite_info_result = info;
  });
};

// 外部兑换码奖励
const gm_exchange_code_reward_result = (store, info) => {
  showToast({
    type: "success",
    message: "奖励到账",
  });
  if (info.gold) {
    store.$patch((state) => {
      state.userInfo.gold = state.userInfo.gold + Number(info.gold);
    });
  } else {
    sendInstructions(
      {
        msg_type: "get_player_common",
      },
      50
    );
  }
};
// 各项记录
const get_all_log_result = (store, info) => {
  store.$patch((state) => {
    const recordData = initRecordData(info, state.initPayPupData);
    recordData.type != "none" &&
      (state.recordDataList[recordData.type] = recordData.list);
  });
};

// 游戏收集反馈
const collect_event_result = (store, info) => {
  store.$patch((state) => {
    state.collect_event_result.push({
      ...info,
      key: Date.now(),
    });
    setTimeout(() => {
      state.collect_event_result.shift();
    }, 3000);
  });
};
// 开启青少年结果
const open_child_result = (store, info) => {
  let child_show = false;
  if (info.error == "ok") {
    if (store.child_info.error_num != info.child_info.error_num) {
      showToast({
        message: `密码错误\n 还有${10 - info.child_info.error_num}次机会`,
      });
      return;
    }

    showToast({
      message: info.type == "close" ? "青少年模式关闭" : "青少年模式开启",
    });

    if (info.type == "close") child_show = false;
    if (info.type == "open") child_show = true;

    store.child_info = {
      ...info.child_info,
      child_show,
    };
  }

  if (info.error != "ok") {
    showToast({
      type: "fail",
      message: info.error,
    });
  }
};
// 青少年数据
const get_player_child_info_result = (store, info) => {
  store.child_info = {
    ...info,
    child_show: info.is_open,
  };
};

// 渠道
const get_channel_result = (store, info) => {
  store.$patch((state) => {
    state.channelType = info;
  });
};
// 游戏彩劵播报
// 获取游戏采集播报

// 获取游戏任务;
const get_player_game_task_result = (store, info) => {
  store.$patch((state) => {
    try {
      state.gameTaskData = initGameViewTaskData(info);
    } catch (error) {
      state.get_player_game_task_result = info;
    }
  });
};

// 游戏任务同步
const update_player_game_task_result = (store, info) => {
  const taskList = JSON.parse(JSON.stringify(store.gameTaskData.taskList));
  if (info.update_type == "update_num") {
    taskList.some((item) => {
      if (
        info.type_name == "collect" &&
        item.task_type == info.type_name + "_" + info.class_id
      ) {
        item.currentNumer = item.currentNumer + info.add_num;
        item.currentNumer >= item.complete &&
          item.statu == 0 &&
          (item.statu = 1);
        return true;
      } else if (
        info.type_name == "up_coin" &&
        info.type_name == item.task_type
      ) {
        item.currentNumer = item.currentNumer + info.add_num;
        item.currentNumer >= item.complete &&
          item.statu == 0 &&
          (item.statu = 1);
        return true;
      } else return false;
    });
  }

  if (info.update_type == "sync_data") {
    taskList.some((item) => {
      if (item.taskIndex == info.index && info.type_name != "all_complete") {
        const step_data = info.data[info.step - 1];
        item.currentNumer = info.num;
        item.complete = step_data.complete;
        item.reward = step_data.reward;
        item.rewardType = step_data.reward_type;

        item.statu =
          info.num >= step_data.complete && !step_data.is_get ? 1 : 0;

        step_data.is_get && (item.statu = 2);

        return true;
      } else return false;
    });
  }

  let getCount = 0;
  taskList.forEach((item) => {
    if (item.statu == 1) getCount++;
  });

  store.$patch((state) => {
    state.gameTaskData.getCount = getCount;

    if (info.type_name == "all_complete") {
      state.gameTaskData.allTaskStatu = 2;
    }

    state.gameTaskData.taskList = taskList;
  });
};

// 领取游戏任务
const get_player_game_task_reward_result = (store, info) => {
  if (info.error == "ok") {
    showToast({
      type: "success",
      message: "领取成功",
      forbidClick: true,
    });

    const allStatu = store.gameTaskData.taskList.some((task) => {
      return task.statu == 0 || task.statu == 1;
    });

    store.$patch((state) => {
      !allStatu && (state.gameTaskData.allTaskStatu = 1);

      info.reward_type == "lottery" &&
        (state.userInfo.lottery += Number(info.reward));

      info.reward_type == "gold" &&
        (state.userInfo.gold += Number(info.reward));

      info.reward_type == "tiaozhan_ticket" &&
        (state.userInfo.tiaozhan_ticket += Number(info.reward));
    });
  } else {
    showToast({
      type: "fail",
      message: "领取失败",
    });
  }
};

// 比赛同步
const sync_active_config_result = (store, info) => {
  store.$patch((state) => {
    if (info.type == "delete") {
      state.initActiveConfigList = state.initActiveConfigList.filter((item) => {
        return item.active_index != info.active_index;
      });
    } else if (info.type == "update_data") {
      sendInstructions({
        msg_type: "get_shipin_active_config",
      });
    }
  });
};

// 玩家比赛信息同步
const sync_player_active_result = (store, info) => {
  store.$patch((state) => {
    // 挑战时间
    state.sync_player_free_match_time = info.free_match_time || 0;

    // 继续挑战
    state.gameIsWatch &&
      !state.get_player_tiaozhan_info_show &&
      (state.get_player_tiaozhan_info_show = true);

    // 类型
    info.active_type &&
      state.settlementResult.type != info.active_type &&
      (state.settlementResult.type =
        info.active_type == "tiaozhan_tz" ? "challenge" : "active");

    // 游戏名
    info.game_name &&
      state.settlementResult.game_name != info.game_name &&
      (state.settlementResult.game_name = info.game_name);
  });
};
// 玩家比赛最终结果
const active_result = (store, info) => {
  store.$patch((state) => {
    if (info.shipin_active_rank) {
      state.settlementResult.type = "active";
      state.settlementResult.score = info.shipin_active_rank.num;
    } else {
      state.settlementResult.type = "challenge";
      state.settlementResult.ret = info.ret;
      state.settlementResult.reward = info.reward;
      state.settlementResult.lottery = info.lottery;
      state.settlementResult.dashi_score = info.dashi_score;
      state.settlementResult.dashi_card = info.dashi_card;
      state.settlementResult.zongshi_card = info.zongshi_card;
    }

    // 防掉线关闭
    state.get_player_tiaozhan_info_show &&
      (state.get_player_tiaozhan_info_show = false);

    // 资源增加
    // info.lottery && (state.userInfo.lottery += Number(info.lottery));
    info.reward && (state.userInfo.gold += Number(info.reward));
    info.dashi_score &&
      (state.userInfo.dashi_score += Number(info.dashi_score));

    info.dashi_card && (state.userInfo.dashi_card = true);
    info.zongshi_card && (state.userInfo.zongshi_card = true);
  });
};

// 物品信息
const get_item_result = (store, info) => {
  store.cardBagData = initCardBagData(info);
};
// 物品消息通知
const get_item_notify_result = (store, info) => {
  if (!Array.isArray(info) || info.length == 0) return;

  if (store.cardBagData.waitting.length == 0) return;

  const list = [];

  info.forEach((notify) => {
    store.cardBagData.waitting.some((card) => {
      if (card.item_id == notify.item_id) {
        list.push(card);
        return true;
      } else return false;
    });
  });

  store.$patch((state) => {
    state.cardNotifyData.show = true;
    if (list.length > 2) list.length = 2;
    state.cardNotifyData.list = list;
  });
};

// 失败金币返回
const device_up_coin_error_return_result = (store, info) => {
  if (info.gold) {
    store.$patch((state) => {
      state.userInfo.gold = state.userInfo.gold + Number(info.gold);
    });
  }
};

let send_marquee_timer = null;
const send_marquee_result = (store, info) => {
  // content
  // color
  // delay_time
  const _delay_time = info.delay_time * 1000;
  const _content = decodeBase64String(info.content);
  const _color = info.font_color;
  const _speed = parseInt(info.speet);
  send_marquee_timer != null && clearTimeout(send_marquee_timer);

  store.$patch((state) => {
    // store.send_marquee_result = info;
    state.send_marquee_config.show = true;
    state.send_marquee_config.content = _content;
    state.send_marquee_config.color = _color;
    // state.send_marquee_config.speed = _speed;
    send_marquee_timer = setTimeout(() => {
      send_marquee_timer = null;
      state.send_marquee_config.show = false;
    }, _speed);
  });
};

const get_machine_info_by_group_result = (store, info) => {
  if (!Array.isArray(info)) return;
  if (info.length == 0) return;

  if (info[0].child_name == "msqy_line" || info[0].group == "msqy_line") {
    // 思路：魔术奇缘是以line_reward_index 相同为一组 其他的可能就是单纯以 group 分组
    const groupedData = info.reduce((acc, curr) => {
      if (!acc[curr.line_reward_index]) {
        const _line_reward_data = {
          quanpan: 300,
          jp1: 500,
          jp2: 1000,
          jp3: 2000,
        };

        acc[curr.line_reward_index] = {
          line_reward_index: curr.line_reward_index,
          line_reward_data: curr.line_reward_data ?? _line_reward_data,
          level: curr.level,
          exchange: curr.exchange,
          machines: [], // 初始化id列表
        };
      }

      let occupys = [];

      if (Array.isArray(curr.device_info)) {
        curr.device_info.forEach((devItem) => {
          if (devItem.occupy && devItem.occupy.player_id) {
            occupys.push(devItem.occupy);
          }
        });
      }

      acc[curr.line_reward_index].machines.push({
        sp_url: curr.sp_url,
        return_time: Number(curr.return_time) || 0,
        visit_time: curr.visit_time,
        site_limit: curr.site_limit,
        product_id: curr.product_id,
        up_coin: curr.up_coin,
        exchange: curr.exchange,
        product_name: curr.product_name,
        level: curr.level,
        device_info: curr.device_info,
        machine_index: curr.machine_index,
        tiaozhan_index: curr.tiaozhan_index,
        status: curr.status,
        child_name: curr.child_name,
        visits: [],
        occupy: occupys,
        line_reward_index: curr.line_reward_index,
        group: curr.group,
      });
      return acc;
    }, {});
    // 将对象转换为数组（如果需要的话）
    // return Object.values(groupedData);
    const transformArr = Object.values(groupedData);

    store.$patch((state) => {
      state.msqyLineListData = transformArr;
    });
  }

  if (info[0].group == "vip_tbj") {
    const gameDataList = initGameData(info);

    store.$patch((state) => {
      state.gameDataList.vip_tbj = gameDataList;
    });
  }
};

const get_player_turntable_result = (store, info) => {
  if (info.error == "ok") {
    const _score = new Decimal(info.player_turntable.score);
    const _spin_num = new Decimal(info.spin_num);
    const _progress = _score.div(_spin_num).mul(100).toNumber();

    const _win_log = [];
    const _jp_log = [];

    if (Array.isArray(info.spin_log) && info.spin_log.length > 0) {
      info.spin_log.forEach((item) => {
        const _i_data = {
          player_name: decodeBase64String(item.player_name),
          head: item.head || item.head_frame,
          num: item.reward_num,
        };
        _win_log.push(_i_data);
      });
    }

    if (Array.isArray(info.spin_jp_log) && info.spin_jp_log.length > 0) {
      info.spin_jp_log.forEach((item) => {
        const _i_data = {
          player_name: decodeBase64String(item.player_name),
          head: item.head || item.head_frame,
          num: item.reward_num,
        };
        _jp_log.push(_i_data);
      });
    }

    store.$patch((state) => {
      state.player_turntable_data.jp_num = parseInt(info.jp_num);
      state.player_turntable_data.progress = _progress;
      state.player_turntable_data.turn_info = info.turn_info;
      state.player_turntable_data.win_log = _win_log;
      state.player_turntable_data.jp_log = _jp_log;
    });
  } else {
    // showToast({
    //   type: "fail",
    //   message: "获取失败，稍后重试",
    // });
  }
};

const sync_player_turntable_result = (store, info) => {
  const _score = new Decimal(info.score);
  const _spin_num = new Decimal(info.spin_num);
  const _progress = _score.div(_spin_num).mul(100).toNumber();

  store.$patch((state) => {
    state.player_turntable_data.progress = _progress;
  });
};

// 夺宝
const get_game_table_list_result = (store, info) => {
  const isPass = info && Array.isArray(info) && info.length > 0;
  if (!isPass) return;

  const duobaoList = [];

  let group_num = parseInt(info.length / 3);

  info.length % 3 > 0 && group_num++;

  for (let i = 0; i < group_num; i++) {
    duobaoList.push({
      level: 1,
      exchange: 20,
      machines: [], // 初始化id列表
    });
  }

  let _index = 0;
  info.forEach((item) => {
    const player_info = {
      uid: "",
      head: "",
      select_title: 0,
      recharge_level: 1,
    };
    const _occupy = [];
    if (item.uid && item.player_head_info) {
      player_info.uid = item.uid;
      player_info.head =
        item.player_head_info.head || item.player_head_info.head_frame;

      player_info.recharge_level = item.player_head_info.recharge_level;
      player_info.select_title = item.player_head_info.select_title;

      _occupy.push(player_info);
    } else if (item.wait_enter != -1 || item.wait_enter > 0) {
      player_info.head = 1;
      player_info.select_title = 0;
      player_info.recharge_level = 1;
      player_info.uid = item.wait_enter;
      _occupy.push(player_info);
    }

    const initItem = {
      sp_url: "",
      return_time: 0,
      visit_time: 60,
      site_limit: item.gold_limit || 0,
      product_id: "",
      up_coin: 1,
      exchange: item.exchange_bet,
      product_name: "duobao",
      level: item.level_limit ?? 1,
      machine_index: item.table_id,
      tiaozhan_index: 1,
      status: item.table_lock ? 0 : 1,
      child_name: "duobao",
      visits: [],
      occupy: _occupy,
      line_reward_index: 0,
      group: "",
    };

    if (item.table_id % 3 == 1) {
      duobaoList[_index].level = item.level_limit;
      duobaoList[_index].exchange = item.exchange_bet;
    }

    duobaoList[_index].machines.push(initItem);
    item.table_id % 3 == 0 && _index++;
  });

  store.$patch((state) => {
    state.duobaoListData = duobaoList;
  });
};

const sync_player_sprog_task_result = (store, info) => {
  store.$patch((state) => {
    state.sprogTaskData.current_step = info.step;
    state.sprogTaskData.end_time = info.end_time * 1000;

    state.sprogTaskData.datas[3].task_info.task[0].num = info.step;
    state.sprogTaskData.datas[6].task_info.task[0].num = info.step;

    info.task.forEach((iitt, iind) => {
      let isNoDone = false;
      for (const _i in iitt) {
        const _item = iitt[_i];
        const _index = parseInt(_i) - 1;

        state.sprogTaskData.datas[iind].task_info.task[_index].complete_num =
          _item.complete_num;
        state.sprogTaskData.datas[iind].task_info.task[_index].num = _item.num;
        if (_item.complete_num > _item.num && !isNoDone) {
          isNoDone = true;
        }
      }
      state.sprogTaskData.datas[iind].task_info.isNoDone = isNoDone;
    });

    _task_award_all.forEach((item, sIndex) => {
      state.sprogTaskData.datas[sIndex].task_info.task_award = item;
    });
  });
};

const notify_tiaozhan_success_result = (store, info) => {
  store.$patch((state) => {
    state.notify_tiaozhan_success_result = info;
  });
};

const create_tiaozhan_red_package_result = (store, info) => {
  store.$patch((state) => {
    state.create_tiaozhan_red_package_result = info;
  });
};

const callBackEventList = {
  create_tiaozhan_red_package_result,
  notify_tiaozhan_success_result,
  get_machine_info_by_group_result,
  net_close,
  get_player_common,
  get_machine_info,
  return_lottery_result,
  up_coin_result,
  notify_mqtt_counting_result,
  get_tianti_rank_result,
  collect_event_result,
  get_player_collect_result,
  exchange_collect_result,
  get_nangua_rank_result,
  get_player_recharge_status_result,
  exchange_lottery_result,
  player_recharge_result,
  get_server_time_result,
  tuibiji_return_lottery_result,
  get_login_reward_result,
  get_player_weal_box_result,
  exchange_player_weal_box_result,
  get_player_weal_box_log_result,
  bind_invite_result,
  get_invite_reward_log_result,
  get_invite_info_result,
  get_all_log_result,
  stop_service_result,
  open_child_result,
  get_player_child_info_result,
  player_recharge_cancel_result,
  get_channel_result,
  get_player_game_task_result,
  update_player_game_task_result,
  get_player_game_task_reward_result,
  gm_exchange_code_reward_result,
  sync_active_config_result,
  active_result,
  sync_player_active_result,
  get_item_result,
  get_item_notify_result,
  device_up_coin_error_return_result,
  send_marquee_result,
  sync_player_turntable_result,
  get_player_turntable_result,
  get_game_table_list_result,
  sync_player_sprog_task_result,
};
// 这里是数据处理
export const handleCallBackEvent = (msg_type, info) => {
  let _msg_type = msg_type;

  callBackEventList[_msg_type] &&
    callBackEventList[_msg_type](useStore(), info);
};
