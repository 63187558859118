<template>
  <div class="guoqing_wrapper">
    <NavBar fixed />
    <div class="in_focus_bg_img" v-show="in_focus_bg_img_show">
      <van-image
        width="100%"
        height="auto"
        block
        :show-loading="false"
        :src="guoqing_bg"
        @load="bg_load_succ"
      />

      <div class="rule_msg">
        活动截止时间：10月7日23点59分;<br />
        1.月饼说明：在活动期间玩家获得所有奖项为月饼，每获得1个奖励，+1个月饼。注：中秋假期月饼X2 <br />
        2.宝箱说明：宝箱分为铁宝箱、铜宝箱、银宝箱、金宝箱、钻石宝箱，每个宝箱需要消耗对应的月饼或宝石，宝箱开启后获得随机宝物奖励 。<br />
        3.宝石说明：宝石分为蓝宝石、绿宝石、红宝石。宝石可在游戏《秘境夺宝》中通过开启宝箱获得和在限时充值活动中购买宝石礼包获得。<br />
      </div>
    </div>

    <div class="log_en_wrap" @click="log_show_toggle(true)"></div>

    <div class="my_stones">
      <div class="stone_item" v-for="(_v, _k) in stone_icon_maps">
        <div class="stone_cover">
          <van-image
            width="100%"
            height="100%"
            block
            :show-loading="false"
            :src="_v.url"
          />
        </div>
        <div class="stone_val">{{ `${_v.text}:${RES[_k]}` }}</div>
      </div>
    </div>

    <div class="recharge_icon" @click="goToR"></div>

    <div class="box_list_wrapper">
      <div class="top_box_wrap">
        <div class="box_item_1">
          <div class="open_box_btn" @click="handle_box_click_event(1)"></div>
        </div>
        <div class="box_item_2">
          <div class="open_box_btn" @click="handle_box_click_event(2)"></div>
        </div>
        <div class="box_item_3">
          <div class="open_box_btn" @click="handle_box_click_event(3)"></div>
        </div>
      </div>
      <div class="bottom_box_wrap">
        <div class="box_item_4">
          <div class="open_box_btn" @click="handle_box_click_event(4)"></div>
        </div>
        <div class="box_item_5">
          <div class="open_box_btn" @click="handle_box_click_event(5)"></div>
        </div>
      </div>
    </div>

    <van-overlay :show="open_box_mask_show" z-index="1000" duration="0">
      <div class="open_box_mask_wrap">
        <div class="_container">
          <div
            class="close_icon"
            v-show="open_box_step == 1"
            @click="resetAll"
          ></div>
          <div class="box_name" v-show="open_box_step == 1">
            {{ box_infos[current_open_box_index - 1].name }}
          </div>

          <div class="box_wrapper">
            <div
              class="bottom_1"
              v-show="current_open_box_index > 3 && open_box_step == 3"
            ></div>
            <div
              class="bottom_2"
              v-show="current_open_box_index > 3 && open_box_step < 4"
            ></div>
            <!-- <div
              class="bottom_3"
              v-show="current_open_box_index > 3 && open_box_step < 4"
            ></div> -->
            <!-- <transition name="van-fade">
              <div
                class="bottom_5"
                v-show="current_open_box_index >= 1 && open_box_step == 3"
              ></div>
            </transition> -->

            <div
              class="bottom_7"
              v-show="current_open_box_index == 5 && open_box_step == 3"
            ></div>
            <!-- <div class="bottom_8" v-show="open_box_step == 3"></div> -->
            <div
              :class="{
                box_img: true,
                'shake-bottom-class': open_box_step == 2,
              }"
            >
              <div class="box_img_1">
                <transition name="van-fade">
                  <van-image
                    v-show="open_box_step < 3"
                    width="100%"
                    height="auto"
                    block
                    :show-loading="false"
                    :src="box_infos[current_open_box_index - 1].close_icon"
                  />
                </transition>
              </div>
              <div class="box_img_2">
                <transition name="van-fade">
                  <van-image
                    v-show="open_box_step == 3"
                    width="100%"
                    height="auto"
                    block
                    :show-loading="false"
                    :src="box_infos[current_open_box_index - 1].open_icon"
                  />
                </transition>
              </div>
            </div>
          </div>

          <div
            class="win_list"
            v-if="open_box_step == 3"
            @animationstart="HandleAnimationstart"
            @animationend="HandleAnimationend(3)"
          >
            <div
              class="win_item"
              v-for="(rItem, indx) in raward_win_list"
              :style="getAst(indx)"
            >
              <van-image
                width="100%"
                height="auto"
                block
                :show-loading="false"
                :src="raward_infos[rItem.type].url"
              />
              <p class="item_text">
                {{ rItem.text }}
              </p>

              <div
                class="type_line"
                v-if="
                  rItem.type == 'zhongguoxin' ||
                  rItem.type == 'gaoshou' ||
                  rItem.type == 'zhanshi' ||
                  rItem.type == 'wangzhe'
                "
              ></div>
            </div>
          </div>

          <div
            class="aatext"
            v-if="current_open_box_index >= 3 && open_box_step == 1"
          >
            <span v-if="must_num_data[3].show && current_open_box_index == 3">
              再开
              <b style="color: red; font-weight: 1000">{{
                must_num_data[3].residue_num
              }}</b>
              次必得战士称号
            </span>
            <span v-if="must_num_data[4].show && current_open_box_index == 4">
              再开
              <b style="color: red; font-weight: 1000">{{
                must_num_data[4].residue_num
              }}</b>
              次必得高手称号
            </span>
            <span v-if="must_num_data[5].show && current_open_box_index == 5">
              再开
              <b style="color: red; font-weight: 1000">{{
                must_num_data[5].residue_num
              }}</b>
              次必得王者称号
            </span>
          </div>

          <div class="raward_list_wrapper" v-if="open_box_step == 1">
            <div class="title">
              {{ box_infos[current_open_box_index - 1].text2 }}
            </div>
            <div class="list">
              <div class="list_item" v-for="rItem in box_raward_list">
                <van-image
                  width="100%"
                  height="auto"
                  block
                  :show-loading="false"
                  :src="rItem.url"
                />
              </div>
            </div>
          </div>

          <div class="get_pup_wrapper" v-if="open_box_step == 4">
            <div class="start_mask"></div>
            <div class="get_win_list">
              <div
                class="get_win_item"
                v-for="(rItem, indx) in raward_win_list"
              >
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :show-loading="false"
                  :src="raward_infos[rItem.type].url"
                />
                <p class="item_text">
                  {{ rItem.text }}
                </p>
              </div>
            </div>

            <div class="get_btn" @click.stop="resetAll"></div>
          </div>

          <div
            class="open_btn"
            @click.stop="handleStartOpenBox"
            v-show="open_box_step == 1"
          >
            <div class="box_limit_text">
              {{ box_infos[current_open_box_index - 1].text }}
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-popup
      v-model:show="log_show"
      :style="{
        background: 'none',
      }"
      :close-on-click-overlay="false"
    >
      <div class="log_wrapper">
        <div class="close_icon" @click="log_show_toggle(false)"></div>
        <div class="log_list">
          <div
            class="log_item"
            v-for="logItem in box_log_list"
            :key="logItem.key"
          >
            <div class="time">{{ logItem.time }}</div>

            <div class="content">
              <div class="box_name">{{ logItem.box_name }}</div>
              <div class="text">{{ logItem.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
const guoqing_bg =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/guoqing_bg.png";

const stone_blue_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/stone_blue.png";
const stone_green_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/stone_green.png";
const stone_red_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/stone_red.png";
const yuebing_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/yuebing.png";

const raward_gold =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_gold.png";
const raward_lottery =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_lottery.png";
const raward_r_card =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_r_card.png";
const raward_tz_card =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_tz_card.png";

const raward_gold_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_gold_2.png";
const raward_lottery_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_lottery_2.png";
const raward_r_card_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_r_card_2.png";
const raward_tz_card_2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_tz_card_2.png";

const raward_ae_A =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_ae_A.png";
const raward_ae_B =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_ae_B.png";
const raward_ae_C =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_ae_C.png";
const raward_ae_D =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/raward_ae_D.png";

const box_1_close =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_1_close.png";
const box_2_close =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_2_close.png";
const box_3_close =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_3_close.png";
const box_4_close =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_4_close.png";
const box_5_close =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_5_close.png";

const box_1_open =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_1_open.png";
const box_2_open =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_2_open.png";
const box_3_open =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_3_open.png";
const box_4_open =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_4_open.png";
const box_5_open =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_5_open.png";

import openBoxHint from "@/assets/audios/open_box.mp3";
import openBoxGoldHint from "@/assets/audios/open_box_gold.mp3";
import openBoxShakeHint from "@/assets/audios/box_shake.mp3";
import NavBar from "@/components/NavBar.vue";
import { formatTimestamp } from "@/utils/utils";

import { getGuoqingReward } from "@/api";

import { Howl, Howler } from "howler";

import {
  ref,
  onBeforeMount,
  computed,
  nextTick,
  onBeforeUnmount,
  reactive,
} from "vue";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";

import Decimal from "decimal.js";

import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { showToast, closeToast } from "vant";
const store = useStore();
const router = useRouter();

//#region 渐加载高清图
const in_focus_bg_img_show = ref(false);
const bg_load_succ = () => {
  in_focus_bg_img_show.value = true;
};
//#endregion

//#region 声音
const openBoxSound = new Howl({
  src: [openBoxHint],
  autoplay: false,
  loop: false,
});
const openBoxGoldSound = new Howl({
  src: [openBoxGoldHint],
  autoplay: false,
  loop: false,
});
const openBoxShakeSound = new Howl({
  src: [openBoxShakeHint],
  autoplay: false,
  loop: false,
});

//#endregion

//#region 获取数据
const RES = ref({
  stone_green: 0,
  uid: 1,
  update_time: 1725292800,
  stone_red: 0,
  stone_blue: 0,
  total_box_info: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
  yuebing: 0,
  box_info: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
});

const get_sent_data = async () => {
  const params = {
    msg_type: "get_player_guoqing",
  };
  try {
    const res = await asyncSendInstructions(params);
    RES.value = res;

    if (res.total_box_info[3] < 10) {
      must_num_data[3].residue_num = 10 - res.total_box_info[3];
    }

    if (res.total_box_info[4] < 50) {
      must_num_data[4].residue_num = 50 - res.total_box_info[4];
    }

    if (res.total_box_info[5] < 100) {
      must_num_data[5].residue_num = 100 - res.total_box_info[5];
    }
  } catch (error) {
    // console.log("errrr", error);
  }
};

const open_box_mask_show = ref(false);
const open_box_mask_show_toggle = (statu) => {
  open_box_mask_show.value = statu;
};
//#endregion

//#region 宝箱信息

// 材料宝石
const stone_icon_maps = {
  yuebing: {
    url: yuebing_icon,
    text: "月饼",
  },
  stone_red: {
    url: stone_red_icon,
    text: "红宝石",
  },

  stone_green: {
    url: stone_green_icon,
    text: "绿宝石",
  },
  stone_blue: {
    url: stone_blue_icon,
    text: "蓝宝石",
  },
};

const current_open_box_index = ref(1);

const box_infos = [
  {
    name: "铁宝箱",
    open_icon: box_1_open,
    close_icon: box_1_close,
    text: "每日限3次(青铜I以上五次)",
    text2: "开启后概率获得以下一个宝物",
  },
  {
    name: "铜宝箱",
    open_icon: box_2_open,
    close_icon: box_2_close,
    text: "每日限2次(白银III以上五次)",
    text2: "开启后概率获得以下一至两个宝物",
  },
  {
    name: "银宝箱",
    open_icon: box_3_open,
    close_icon: box_3_close,
    text: "青铜1以上可开(不限次数)",
    text2: "开启后概率获得以下一至两个宝物",
  },
  {
    name: "金宝箱",
    open_icon: box_4_open,
    close_icon: box_4_close,
    text: "白银III以上可开(不限次数)",
    text2: "开启后概率获得以下一至三个宝物",
  },
  {
    name: "钻石宝箱",
    open_icon: box_5_open,
    close_icon: box_5_close,
    text: "白银III以上可开(不限次数)",
    text2: "开启后概率获得以下一至四个宝物",
  },
];

const handle_box_click_event = (index) => {
  current_open_box_index.value = index;
  open_box_mask_show_toggle(true);
};

const raward_win_list = ref([]);

const box_raward_list = computed(() => {
  const rlist = [
    {
      url: raward_gold,
      text: "金币",
    },
    {
      url: raward_lottery,
      text: "彩券",
    },
    {
      url: raward_tz_card,
      text: "挑战卡",
    },
    {
      url: raward_r_card,
      text: "充值加成卡",
    },
    {
      url: raward_ae_A,
      text: "我的中国心",
    },
    {
      url: raward_ae_B,
      text: "战士称号",
    },
    {
      url: raward_ae_C,
      text: "高手称号",
    },
    {
      url: raward_ae_D,
      text: "王者称号",
    },
  ];

  const box1 = [rlist[0], rlist[1], rlist[2], rlist[4]];
  const box2 = [rlist[0], rlist[1], rlist[2], rlist[4]];
  const box3 = [rlist[0], rlist[1], rlist[2], rlist[4], rlist[5]];
  const box4 = [rlist[0], rlist[1], rlist[2], rlist[3], rlist[6]];
  const box5 = [rlist[0], rlist[1], rlist[2], rlist[3], rlist[7]];

  const maps = {
    1: box1,
    2: box2,
    3: box3,
    4: box4,
    5: box5,
  };

  return maps[current_open_box_index.value];
});

const raward_infos = {
  gold: {
    text: "金币",
    url: raward_gold_2,
  },
  lottery: {
    text: "彩券",
    url: raward_lottery_2,
  },
  tz_card: {
    text: "挑战卡",
    url: raward_tz_card_2,
  },
  jiacheng_card: {
    text: "加成卡",
    url: raward_r_card_2,
  },
  zhongguoxin: {
    text: "中国心",
    url: raward_ae_A,
  },
  zhanshi: {
    text: "战士称号",
    url: raward_ae_B,
  },
  gaoshou: {
    text: "高手称号",
    url: raward_ae_C,
  },
  wangzhe: {
    text: "王者称号",
    url: raward_ae_D,
  },
};

//#endregion

//#region 开箱动画
const handleStartOpenBox = async () => {
  let limit_num = 3;

  const box_num = RES.value.box_info[current_open_box_index.value];

  // 铁宝箱
  if (current_open_box_index.value == 1) {
    store.userInfo.recharge_level > 3 && (limit_num = 5);
  }

  // 银宝箱
  if (current_open_box_index.value == 2) {
    limit_num = 2;
    store.userInfo.recharge_level > 3 && (limit_num = 5);
  }

  if (
    box_num >= limit_num &&
    (current_open_box_index.value == 1 || current_open_box_index.value == 2)
  ) {
    showToast({
      type: "fail",
      message: "今日已上限",
    });
    return;
  }

  if (current_open_box_index.value == 3 && store.userInfo.recharge_level < 3) {
    showToast({
      type: "fail",
      message: "青铜I以上可开",
    });

    return;
  }

  if (current_open_box_index.value >= 4 && store.userInfo.recharge_level < 4) {
    showToast({
      type: "fail",
      message: "白银III以上可开",
    });
    return;
  }

  showToast({
    type: "loading",
    forbidClick: true,
    duration: 0,
  });

  const _raward_win_list = [];

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_guoqing_reward",
      index: current_open_box_index.value,
    });

    // const res = {
    //   error: "ok",
    //   reward_data: [
    //     {
    //       type: "lottery",
    //       num: 857,
    //     },
    //     {
    //       type: "gold",
    //       num: 666,
    //     },
    //   ],
    //   must_title: {
    //     max_num: 100,
    //     type: "chenghao",
    //     title_name: "wangzhe",
    //     class_id: 1,
    //   },
    // };

    closeToast();
    if (res.error == "ok") {
      let _lottery_num = 0;
      let _gold_num = 0;
      let _tiaozhan_ticket = 0;

      res.reward_data.forEach((item) => {
        const _item = {
          ...item,
          text: "",
        };

        if (item.type == "chenghao") {
          _item.type = item.title_name;
          _item.text = "";
        } else if (item.type == "item") {
          _item.type = "jiacheng_card";
          const vv = new Decimal(item.num).sub(1).mul(100).toNumber();

          _item.text = vv + "%";
        } else {
          _item.text = "+" + item.num;
        }

        if (item.type == "gold") _gold_num += Number(item.num);
        if (item.type == "lottery") _lottery_num += Number(item.num);
        if (item.type == "tz_card") _tiaozhan_ticket += Number(item.num);

        _raward_win_list.push(_item);
      });

      if (res.must_title) {
        _raward_win_list.push({
          type: res.must_title.title_name,
        });
      }

      store.$patch((state) => {
        state.userInfo.gold = state.userInfo.gold + _gold_num;
        state.userInfo.lottery = state.userInfo.lottery + _lottery_num;
        state.userInfo.tiaozhan_ticket =
          state.userInfo.tiaozhan_ticket + _tiaozhan_ticket;
      });

      raward_win_list.value = _raward_win_list;
      get_sent_data();
      nextTick(() => {
        open_box_step.value = 2;
        setTimeout(() => {
          openBoxShakeSound.play();
        }, 1e3);
        setTimeout(() => {
          open_box_step.value = 3;
        }, 2600);
      });
    } else {
      const msglistmaps = {
        "exchange item not enough": "物品不足",
        "exchange num limit": "兑换上限",
        "guoqing time limit": "活动已结束",
        "no guoqing config": "活动已结束",
      };

      showToast({
        type: "fail",
        message: msglistmaps[res.error] ?? res.error,
      });
    }
  } catch (error) {
    closeToast();
    showToast({
      type: "fail",
      message: "稍后重试",
    });
  }
};

const getAst = (index) => {
  const _1 = {
    "-webkit-animation":
      "winItem_1 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) both",
    animation: "winItem_1 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) both",
    animationDelay: `${index + 0.5}s`,
  };

  const _2 = {
    "-webkit-animation":
      "winItem_2 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) both",
    animation: "winItem_2 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) both",
    animationDelay: `${index + 0.5}s`,
  };
  const _3 = {
    "-webkit-animation":
      "winItem_3 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) both",
    animation: "winItem_3 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) both",
    animationDelay: `${index + 0.5}s`,
  };
  const _4 = {
    "-webkit-animation":
      "winItem_4 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) both",
    animation: "winItem_4 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) both",
    animationDelay: `${index + 0.5}s`,
  };

  if (raward_win_list.value.length == 1) {
    return _2;
  }

  if (raward_win_list.value.length == 2) {
    if (index == 0) return _2;
    if (index == 1) return _3;
  }

  if (raward_win_list.value.length == 3) {
    if (index == 0) return _2;
    if (index == 1) return _3;
    if (index == 2) return _4;
  }

  if (index == 0 || index == 0.2) return _1;
  if (index == 1 || index == 1.2) return _2;
  if (index == 2 || index == 2.2) return _3;
  if (index == 3 || index == 3.2) return _4;
};

const open_box_step = ref(1);
let win_Animationend_num = 0;

const HandleAnimationstart = () => {
  openBoxGoldSound.play();
};

const HandleAnimationend = (f) => {
  win_Animationend_num++;
  if (win_Animationend_num == raward_win_list.value.length) {
    setTimeout(() => {
      openBoxSound.play();
      open_box_step.value = 4;
    }, 15e2);
  }
};

const resetAll = () => {
  open_box_mask_show_toggle(false);
  raward_win_list.value = [];
  open_box_step.value = 1;
  win_Animationend_num = 0;
};

//#endregion

//#region 计算必得次数 已经是否提前解锁
const must_num_data = reactive({
  1: {
    must_num: 100,
    residue_num: 0,
    show: false,
  },
  2: {
    must_num: 100,
    residue_num: 0,
    show: false,
  },
  3: {
    must_num: 100,
    residue_num: 0,
    show: true,
  },
  4: {
    must_num: 100,
    residue_num: 0,
    show: true,
  },
  5: {
    must_num: 100,
    residue_num: 0,
    show: true,
  },
});
const get_user_achievement_data = async () => {
  const res = await asyncSendInstructions({
    msg_type: "get_player_title",
  });

  if (res.data[13].complete <= res.data[13].num) {
    must_num_data[3].show = false;
  }
  if (res.data[14].complete <= res.data[14].num) {
    must_num_data[4].show = false;
  }
  if (res.data[15].complete <= res.data[15].num) {
    must_num_data[5].show = false;
  }
};

//#endregion

//#region
const log_show = ref(false);

const log_show_toggle = (statu) => {
  log_show.value = statu;
  if (statu) get_box_log_data();
};

const box_log_list = ref([]);

const get_box_log_data = async () => {
  const res = await getGuoqingReward({
    uid: parseInt(store.userInfo.uid),
  });

  const _init_list = [];
  // let _indexxx = 0;
  for (const key in res.data) {
    const item = res.data[key];
    // _indexxx++;

    // if (_indexxx > 50) break;

    const _init_item = {
      time: formatTimestamp(item.time, "mm-dd \n hh:mi:ss"),
      key: item._id,
      box_name: box_infos[Number(item.index) - 1].name,
      text: "获得 ",
    };

    item.reward_data.forEach((ii) => {
      if (ii.type == "chenghao") {
        _init_item.text += "+" + raward_infos[ii.title_name].text;
      } else if (ii.type == "item") {
        const vv = new Decimal(ii.num).sub(1).mul(100).toNumber();
        _init_item.text += "+" + vv + "% 加成卡";
      } else _init_item.text += "+" + ii.num + raward_infos[ii.type].text;
    });

    if (item.must_title) {
      _init_item.text += "+" + raward_infos[item.must_title.title_name].text;
    }

    _init_list.unshift(_init_item);
  }

  box_log_list.value = _init_list;
};

//#endregion

const goToR = () => {
  router.replace("/rRecharge");
};

onBeforeMount(() => {
  get_sent_data();
  setTimeout(() => {
    get_user_achievement_data();
  }, 1000);
});

onBeforeUnmount(() => {
  openBoxSound.unload();
  openBoxGoldSound.unload();
  openBoxShakeSound.unload();
});
</script>

<style lang="scss">
.guoqing_wrapper {
  position: relative;
  // min-height: 100vh;
  min-height: 1337px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/guoqing_bg1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .in_focus_bg_img {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;

    .rule_msg {
      // font-weight: 800;
      padding: 20px 22px;

      padding-bottom: 60px;
      font-size: 14px;
      color: #fdd9a6;
      line-height: 26px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      background-color: #251d4c;
    }
  }
}

.log_en_wrap {
  position: absolute;
  top: 180px;
  right: 0;
  width: 28px;
  height: 68px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/log_en_icon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 33;
}

.my_stones {
  position: absolute;
  top: 510px;
  width: 100%;
  height: 80px;

  z-index: 2;

  display: flex;
  padding: 0 24px 0 34px;

  justify-content: space-between;
  .stone_item {
    width: 60px;
    height: 100%;

    .stone_cover {
      width: 60px;
      height: 60px;
    }

    .stone_val {
      line-height: 20px;
      font-size: 12px;
      text-align: center;
      font-weight: 800;
      white-space: nowrap;
    }
  }
}

.box_list_wrapper {
  position: absolute;
  top: 680px;
  width: 100%;
  height: 350px;

  z-index: 2;

  .top_box_wrap {
    width: 100%;
    height: 154px;
    display: flex;
    justify-content: center;
    .box_item_1 {
      position: relative;
      width: 98px;
      height: 100%;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_1.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin-right: 10px;
      // background-color: teal;
    }

    .box_item_2 {
      @extend .box_item_1;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_2.png");
    }
    .box_item_3 {
      @extend .box_item_1;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_3.png");
      margin-right: 0;
    }
  }

  .bottom_box_wrap {
    margin: 20px;
    height: 154px;
    display: flex;
    justify-content: center;

    .box_item_4 {
      position: relative;
      width: 140px;
      height: 100%;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_4.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin-right: 10px;
    }
    .box_item_5 {
      @extend .box_item_4;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_5.png");
      margin-right: 0px;
    }
  }

  .open_box_btn {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100px;
    height: 36px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/open_box_btn_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateX(-50%);
  }
}

.recharge_icon {
  position: fixed;
  position: -webkit-fixed; /* Safari */
  top: 260px;
  right: 10px;
  z-index: 3;
  width: 44px;
  height: 44px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/recharge_icon.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.log_wrapper {
  position: relative;
  width: 306px;
  height: 460px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/log_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 44px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 22px;
  font-size: 10px;

  .close_icon {
    position: absolute;
    top: 20px;
    right: 12px;
    height: 30px;
    width: 30px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .log_list {
    overflow: auto;
    height: 100%;
    .log_item {
      margin-top: 10px;
      height: 54px;
      width: 100%;
      display: flex;
      background-color: #4d479e;
      border-radius: 6px;
      .time {
        // style="white-space: pre;"
        white-space: pre;

        text-align: right;
        width: 60px;
        padding-right: 10px;
        padding-top: 13px;
      }

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.open_box_mask_wrap {
  ._container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 460px;

    .close_icon {
      position: absolute;
      top: 0px;
      right: 40px;
      height: 30px;
      width: 30px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .win_list {
      position: absolute;
      top: 0;
      left: 0px;
      display: flex;
      width: 100%;
      height: 120px;

      justify-content: center;
      z-index: 2;
      .win_item {
        position: relative;
        width: 66px;
        height: 66px;
        margin-right: 10px;

        .item_text {
          position: absolute;
          font-size: 12px;
          bottom: 0;
          width: 100%;
          text-align: center;
          color: #ffae00;
          font-weight: bold;
          height: 24px;
          line-height: 24px;
        }

        .type_line {
          position: absolute;
          width: 80px;
          height: 80px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/boxWinItemSequenceframe.png");
          background-size: 100% auto;
          background-repeat: no-repeat;

          animation-name: boxWinItemSequenceframe;
          animation-duration: 1s;
          animation-timing-function: step-start;
          // animation-delay: 1s;
          animation-iteration-count: infinite;
          // animation-direction: alternate;
          z-index: 8;
        }
      }
    }

    .raward_list_wrapper {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 132px;
      width: 300px;
      .title {
        text-align: center;
        font-size: 10px;
        letter-spacing: 2px;
        // background-color: red;
        color: #fbf3aa;
      }

      .list {
        margin-top: 12px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .list_item {
          width: 66px;
          height: 66px;

          &:nth-child(n + 5) {
            margin-top: 10px;
          }
        }
      }
    }

    .aatext {
      position: absolute;
      left: 0;
      bottom: -60px;
      width: 100%;

      text-align: center;
      color: #fbf3aa;
    }
    .get_pup_wrapper {
      position: absolute;
      left: 50%;
      top: 26%;
      width: 350px;
      height: 346px;
      // background-color: skyblue;
      transform: translate(-50%, -50%);

      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/win_pup_bg.png");
      background-size: 100% auto;
      background-repeat: no-repeat;

      // border-top: 1px solid #b7974e;
      // border-bottom: 1px solid #b7974e;

      -webkit-animation: scale-in-center 0.5s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      .start_mask {
        position: absolute;
        top: 56px;
        left: 116px;
        // transform: translate(-50%, -30%);
        width: 120px;
        height: 100px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_bottom_7.gif");
        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: 6;
      }

      .get_win_list {
        position: absolute;
        top: 198px;
        left: 50%;
        width: 100%;
        height: 66px;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;

        .get_win_item {
          position: relative;
          width: 66px;
          height: 66px;
          // margin-right: 10px;
          margin: 0 10px;
          .item_text {
            position: absolute;
            font-size: 12px;
            bottom: 0px;
            width: 100%;
            text-align: center;
            color: #ffae00;
            font-weight: bold;
            height: 24px;
            line-height: 24px;
          }

          // &::after {
          //   content: "";
          //   position: absolute;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate(-50%, -50%);
          //   width: 84px;
          //   height: 70px;
          //   background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_bottom_7.gif");
          //   background-size: 100% auto;
          //   background-repeat: no-repeat;
          //   z-index: 7;
          // }
        }
      }

      .get_btn {
        position: absolute;
        bottom: -14px;
        left: 50%;
        width: 116px;
        height: 38px;
        transform: translateX(-50%);
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/get_box_btn_bg.png");

        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: 11;
      }
    }

    .box_wrapper {
      position: absolute;
      width: 168px;
      height: 120px;
      left: 50%;
      top: 42%;
      transform: translate(-50%, -50%);
      transition: top 1s;
      z-index: 2;

      .box_img {
        position: relative;
        z-index: 4;
        width: 168px;
        height: 120px;
        .box_img_1 {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .box_img_2 {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        .box_img_3 {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 3;
          background-color: #fff;
          -webkit-animation: scale-in-bottom 0.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: scale-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
        }
      }

      .bottom_1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 262px;
        height: 262px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_bottom_1.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: 1;
        animation: wheeling 4s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }

      .bottom_2 {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 12px);
        width: 288px;
        height: 151px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_bottom_2.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: 2;
      }

      .bottom_3 {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 8px);
        width: 166px;
        height: 52px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_bottom_3.gif");
        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: 3;
      }
      .bottom_5 {
        position: absolute;
        bottom: 54px;
        left: -34px;
        // transform: translateX(-54%);
        width: 226px;
        height: 193px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_bottom_5.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: 5;
        -webkit-animation: scale-in-bottom 0.5s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: scale-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        // background-color: black;
      }
      // .bottom_6 {
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -30%);
      //   width: 132px;
      //   height: 66px;
      //   background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_bottom_6.gif");
      //   background-size: 100% auto;
      //   background-repeat: no-repeat;
      //   z-index: 6;
      // }
      .bottom_7 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 84px;
        height: 70px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/box_bottom_7.gif");
        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: 7;
      }

      .bottom_8 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 338px;
        height: 338px;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/boxSequenceframe.png");
        background-position: 0 338px;
        background-size: 100% auto;
        background-repeat: no-repeat;
        z-index: 8;
        // opacity: 0;
        animation-name: boxSequenceframe;
        animation-duration: 0.5s;
        animation-timing-function: step-start;
        // animation-delay: 1s;
        animation-iteration-count: 1;
        animation-direction: alternate;
      }
    }

    .box_name {
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      height: 34px;
      text-indent: 20px;
      // font-weight: 800;
      letter-spacing: 4px;
      font-size: 22px;
      line-height: 34px;
      white-space: nowrap;
      // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
      text-align: center;
      color: #fbf3aa;

      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }

    .open_btn {
      position: absolute;
      top: 290px;
      left: 50%;
      width: 116px;
      height: 38px;
      transform: translateX(-50%);
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/active/open_box_btn_bg2.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      z-index: 11;

      .box_limit_text {
        position: absolute;
        top: -30px;
        // width: 100%;
        left: 50%;
        transform: translateX(-50%);
        // font-weight: 800;
        font-size: 10px;
        line-height: 34px;
        white-space: nowrap;
        letter-spacing: 2px;
        // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
        text-align: center;
        font-style: normal;
        text-transform: none;
        color: #fbf3aa;
      }
    }
  }

  .open_text {
    position: absolute;
    left: 50%;
    top: 260px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    font-weight: 1000;
    font-size: 16px;
    white-space: nowrap;

    transform: translateX(-50%);
    color: #fbf3aa;
  }

  .box_text {
    position: absolute;
    left: 50%;
    top: 280px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    font-weight: 800;
    color: #fbf3aa;
    transform: translateX(-50%);
  }

  .raward_list {
    position: absolute;
    left: 50%;
    top: 48%;
    width: 200px;
    height: 180px;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .rawrad_item {
      width: 52px;
      height: 70px;

      .rawrad_text {
        text-align: center;
        color: #fbf3aa;
        white-space: nowrap;
      }
    }
  }
}

.shake-bottom-class {
  -webkit-animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
  animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both 2;
  animation-delay: 1s;
}

@-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

@keyframes wheeling {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes wheeling

/* Safari 与 Chrome */ {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes winItem_1 {
  0% {
    -webkit-transform: scale(1) translateY(130px) translateX(120px);
    transform: scale(1) translateY(130px) translateX(120px);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }

  80% {
    -webkit-transform: scale(1.5) translateY(10px) translateX(0);
    transform: scale(1.5) translateY(10px) translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
}
@keyframes winItem_1 {
  0% {
    -webkit-transform: scale(1) translateY(130px) translateX(120px);
    transform: scale(1) translateY(130px) translateX(120px);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }

  80% {
    -webkit-transform: scale(1.5) translateY(10px) translateX(0);
    transform: scale(1.5) translateY(10px) translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes winItem_2 {
  0% {
    -webkit-transform: scale(1) translateY(100px) translateX(30px);
    transform: scale(1) translateY(140px) translateX(50px);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
  }
  80% {
    -webkit-transform: scale(1.5) translateY(10px) translateX(0);
    transform: scale(1.5) translateY(10px) translateX(0);
  }

  100% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
}
@keyframes winItem_2 {
  0% {
    -webkit-transform: scale(1) translateY(100px) translateX(30px);
    transform: scale(1) translateY(140px) translateX(50px);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
  }
  80% {
    -webkit-transform: scale(1.5) translateY(10px) translateX(0);
    transform: scale(1.5) translateY(10px) translateX(0);
  }

  100% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes winItem_3 {
  0% {
    -webkit-transform: scale(1) translateY(100px) translateX(-22px);
    transform: scale(1) translateY(140px) translateX(-22px);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(1.5) translateY(10px) translateX(0);
    transform: scale(1.5) translateY(10px) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
}
@keyframes winItem_3 {
  0% {
    -webkit-transform: scale(1) translateY(100px) translateX(-22px);
    transform: scale(1) translateY(140px) translateX(-22px);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(1.5) translateY(10px) translateX(0);
    transform: scale(1.5) translateY(10px) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes winItem_4 {
  0% {
    -webkit-transform: scale(1) translateY(130px) translateX(-90px);
    transform: scale(1) translateY(130px) translateX(-90px);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(1.5) translateY(10px) translateX(0);
    transform: scale(1.5) translateY(10px) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
}
@keyframes winItem_4 {
  0% {
    -webkit-transform: scale(1) translateY(130px) translateX(-90px);
    transform: scale(1) translateY(130px) translateX(-90px);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(1.5) translateY(10px) translateX(0);
    transform: scale(1.5) translateY(10px) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1) translateY(10px) translateX(0);
    transform: scale(1) translateY(10px) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }
}

@-webkit-keyframes boxSequenceframe {
  0% {
    background-position: 0 0px;
  }
  10% {
    background-position: 0 -338px;
  }
  20% {
    background-position: 0 -676px;
  }
  30% {
    background-position: 0 -1014px;
  }
  40% {
    background-position: 0 -1352px;
  }
  50% {
    background-position: 0 -1690px;
  }
  60% {
    background-position: 0 -2028px;
  }
  70% {
    background-position: 0 -2366px;
  }
  80% {
    background-position: 0 -2704px;
  }
  90% {
    background-position: 0 -3042px;
  }
  100% {
    background-position: 0 -3042px;
  }
}
@keyframes boxSequenceframe {
  0% {
    background-position: 0 0px;
  }
  10% {
    background-position: 0 -338px;
  }
  20% {
    background-position: 0 -676px;
  }
  30% {
    background-position: 0 -1014px;
  }
  40% {
    background-position: 0 -1352px;
  }
  50% {
    background-position: 0 -1690px;
  }
  60% {
    background-position: 0 -2028px;
  }
  70% {
    background-position: 0 -2366px;
  }
  80% {
    background-position: 0 -2704px;
  }
  90% {
    background-position: 0 -3042px;
  }
  100% {
    background-position: 0 -3380px;
  }
}

@-webkit-keyframes boxWinItemSequenceframe {
  0% {
    background-position: 0 0px;
  }
  12.5% {
    background-position: 0 -80px;
  }
  25% {
    background-position: 0 -160px;
  }
  37.5% {
    background-position: 0 -240px;
  }
  50% {
    background-position: 0 -320px;
  }
  62.5% {
    background-position: 0 -400px;
  }
  75% {
    background-position: 0 -480px;
  }
  87.5% {
    background-position: 0 -560px;
  }
  100% {
    background-position: 0 0px;
  }
}
@keyframes boxWinItemSequenceframe {
  0% {
    background-position: 0 0px;
  }
  12.5% {
    background-position: 0 -80px;
  }
  25% {
    background-position: 0 -160px;
  }
  37.5% {
    background-position: 0 -240px;
  }
  50% {
    background-position: 0 -320px;
  }
  62.5% {
    background-position: 0 -400px;
  }
  75% {
    background-position: 0 -480px;
  }
  87.5% {
    background-position: 0 -560px;
  }
  100% {
    background-position: 0 0px;
  }
}
</style>
