<template>
  <div style="position: relative; padding-top: 60px">
    <NavBar />
    <br />
    <br />
    <br />
    <br />
    <br />
    <van-form @submit="onSubmit">
      <h1 style="text-align: center">协议数据</h1>

      <van-cell-group inset>
        <van-field
          v-model="msg_type"
          name="msg_type"
          label="type"
          placeholder="协议名称"
        />
        <van-field
          v-for="(paramsItem, paramsIndex) in params"
          name="params"
          :label="paramsItem[0]"
          placeholder="params_value"
          v-model="paramsItem[1]"
        />
      </van-cell-group>

      <h1 style="text-align: center; margin-top: 16px">操作</h1>
      <div class="addparms">
        <div class="wa">
          <van-field
            v-model="paramsName"
            center
            clearable
            label="参数名"
            placeholder="请输入参数名"
          >
            <template #button>
              <van-button size="small" type="primary" @click="addParams"
                >添加参数</van-button
              >
            </template>
          </van-field>
        </div>
      </div>
      <div style="margin: 16px">
        <van-button round block type="success" native-type="submit">
          提交
        </van-button>
      </div>
      <div style="margin: 16px">
        <van-button round block type="warning" @click="reset">
          重置
        </van-button>
      </div>
    </van-form>

    <h3>返回结果</h3>
    <div class="_result_wrapp">
      <div class="con">
        <pre style="user-select: auto; font-size: 20px; overflow-x: auto">{{
          JSON.stringify(_result, null, 2)
        }}</pre>
      </div>
      <div style="margin: 16px">
        <van-button round block type="warning" @click="handleCopy">
          复制
        </van-button>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <van-collapse v-model="activeNames">
      <van-collapse-item
        v-for="(state, index) in states"
        :key="index"
        :title="index"
      >
        <pre style="user-select: auto; font-size: 20px; overflow-x: auto">{{
          JSON.stringify(state, null, 2)
        }}</pre>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import { ref, computed, reactive, watch } from "vue";
import { useStore } from "@/store/store";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import { showToast, closeToast } from "vant";
import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard();

const store = useStore();
const activeNames = ref([]);
const states = computed(() => {
  return store.$state;
});

const msg_type = ref("");

const params = reactive([]);
const paramsName = ref("");

const _result = ref({});

const addParams = () => {
  if (paramsName.value.trim() == "") {
    showToast({
      type: "fail",
      message: "参数名不可为空",
    });

    return;
  }

  params.push([paramsName.value, ""]);

  paramsName.value = "";
};

const onSubmit = async () => {
  let d_p = {
    msg_type: msg_type.value,
  };

  if (params.length > 0) {
    let k = {};
    params.forEach((pi) => {
      k[pi[0]] = pi[1];
    });

    Object.assign(d_p, k);
    //  d_p= {
    //   ...d_p,
    //   ..._k
    // }
  }

  console.log("d_pd_p", d_p);
  showToast({
    type: "loading",
  });
  try {
    const res = await asyncSendInstructions(d_p);

    console.log("resres11111111111111111111", res);
    closeToast();
    _result.value = res;
  } catch (error) {
    console.log("error", error);
    closeToast();
    showToast({
      type: "fail",
      message: "系统错误",
    });
  }
};

const reset = () => {
  msg_type.value = "";
  _result.value = {};
  paramsName.value = "";
  params.length = 0;
};

const handleCopy = async () => {
  if (!_result.value || Object.keys(_result.value).length == 0) {
    showToast({
      type: "fail",
      message: "不可复制空",
    });
    return;
  }

  try {
    await toClipboard(JSON.stringify(_result.value));
    showToast({
      type: "success",
      message: "复制成功!",
    });
  } catch (e) {
    // console.error(e);
    console.log("error", e);
    showToast({
      type: "fail",
      message: "复制失败!",
    });
  }
};

watch(
  () => [paramsName.value, msg_type.value],
  ([new1, new2]) => {
    console.log("new11111111", new1);
    console.log("new2222222222", new2);
  }
);
</script>

<style lang="scss" scoped>
.addparms {
  padding: 0 20px;

  .wa {
    border-radius: 12px;
    overflow: hidden;
  }
}

._result_wrapp {
  padding: 20px;

  .con {
    border-radius: 8px;

    min-height: 100px;
    background-color: #fff;
    color: #333;
  }
}
</style>
