<template>
  <!-- 收集提示 -->
  <div class="collect_hint_wrapper">
    <div
      class="collect_hint_item"
      v-for="hintItem in store.collect_event_result"
      :key="hintItem.key"
      :style="{
        left: (store.current_device.device_index - 1) * 25 + '%',
      }"
    >
      <div class="collect_cover">
        <van-image
          width="100%"
          height="100%"
          fit="fill"
          position="center"
          :show-loading="false"
          :src="findHintCover(hintItem)"
        />
      </div>
      <div class="collect_num">
        <van-image
          v-show="dubllNum == 1"
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          :show-loading="false"
          :src="card_number"
        />
        <van-image
          v-show="dubllNum == 2"
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          :show-loading="false"
          :src="card_number2"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { watch, ref, computed, onBeforeUnmount } from "vue";
import collectClassIDs from "@/utils/collectClassIDs";

const store = useStore();

const card_number =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_number.png";
const card_number2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/card_number2.png";
// const yue_icon =
//   "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/collect/yue.png";

import { Howl, Howler } from "howler";
import collect_hint from "@/assets/audios/collect_hint.mp3";

const emits = defineEmits(["callback"]);

const collectHintHowl = new Howl({
  src: [collect_hint],
  autoplay: false,
  loop: false,
});

const dubllNum = ref(1);

// if (store.yuebing_mul_start_time != 0 || store.yuebing_mul_end_time != 0) {
//   let _date = Date.now() / 1000;

//   if (
//     _date >= store.yuebing_mul_start_time &&
//     _date < store.yuebing_mul_end_time
//   ) {
//     dubllNum.value = 2;
//   }
// }

const hintSoundPlay = () => {
  collectHintHowl.play();

  // 收集到数据后的后续反馈
  if (store.current_machine.product_name == "bbl") {
    emits("callback");
  }
};

const findHintCover = (hintItem) => {
  return collectClassIDs[hintItem.game_name].find((item) => {
    return item.classID == hintItem.class_id;
  }).url;
};

watch(
  () => store.collect_event_result.length,
  (newV, oldV) => {
    newV > oldV && hintSoundPlay();
  }
);

onBeforeUnmount(() => {
  collectHintHowl.unload();
});
</script>

<style lang="scss" scoped>
.collect_hint_wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 100%;
  height: 25vh;

  .collect_hint_item {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 25vh;
    opacity: 1;

    animation: barrage_hint 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;

    .collect_cover {
      width: 60px;
      height: 60px;
    }

    .collect_num {
      width: 60px;
      height: 60px;
    }
  }
}

.animationClass1,
.animationClass2,
.animationClass3,
.animationClass4,
.animationClass5 {
  animation: barrage_hint 3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  // animation-iteration-count: infinite;
}

@keyframes barrage_hint {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(-600%);
    opacity: 0;
  }
}

@-webkit-keyframes barrage_hint

/* Safari 与 Chrome */ {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    transform: translateY(-600%);
    opacity: 0;
  }
}
</style>
