<template>
  <div class="float_menu_wrapper">
    <div
      class="m_item"
      v-for="mItem in menuList"
      @click="suspension_menu_event(mItem.type)"
      :style="{
        backgroundPosition: `-${mItem.bg_index * 1.3867}rem 0`,
      }"
      v-show="mItem.show"
    >
      <!-- <div class="m_icon">
        <van-image
          width="100%"
          height="100%"
          block
          position="center"
          :src="mItem.icon"
          :show-loading="false"
        />
      </div> -->
      <div class="slots_num" v-if="mItem.type == 'slots'">
        剩余{{ slots_num }}次
      </div>
    </div>
  </div>
  <!-- 登录抽奖 -->
  <LoginSolts ref="_Slots" />
</template>

<script setup>
import LoginSolts from "@/components/LoginSolts.vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
import {
  onBeforeUnmount,
  onMounted,
  onBeforeMount,
  ref,
  reactive,
  computed,
  watch,
} from "vue";

const router = useRouter();
const store = useStore();

const menuList = reactive([
  {
    type: "slots",
    bg_index: 3,
    show: true,
  },
  {
    type: "sprog",
    bg_index: 4,
    show: false,
  },
  {
    type: "vacancy",
    bg_index: 0,
    show: false,
  },
  {
    type: "update",
    bg_index: 1,
    show: false,
  },
  {
    type: "service",
    bg_index: 2,
    show: true,
  },
]);
//#region 登陆抽奖
const _Slots = ref();
const slots_num = computed(() => {
  if (!_Slots.value) return 0;
  return _Slots.value.data_config.free_num;
});

//#endregion

//#region 新手任务
onMounted(() => {
  if (store.userInfo.register_time) {
    menuList[1].show = true;
  }
});

//#endregion

const suspension_menu_event = (type) => {
  if (type == "slots") {
    _Slots.value?.exposeOuterControlSlotShow(true);
  }

  if (type == "vacancy") {
    console.log("空位空位");
  }

  if (type == "sprog") {
    store.sprogTaskShowToggle(true);
    console.log("新手任务");
  }

  if (type == "update") {
    console.log("刷新");
  }

  if (type == "service") {
    router.push("/question");
  }
};
</script>

<style lang="scss" scoped>
.float_menu_wrapper {
  position: fixed;
  right: 10px;
  bottom: 90px;

  width: 52px;
  // height: 208px;
  //   height: 104px;
  z-index: 99;
  transform: translateY(100%, 0);
  //   transform-origin: bottom center;
  //   background-color: pink;

  .m_item {
    position: relative;
    width: 52px;
    height: 52px;
    background-image: url("@/assets/images/common/home_p_icons2.png");
    background-size: 260px 52px;

    .m_icon {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    .slots_num {
      position: absolute;
      width: 100%;
      bottom: 6px;
      font-size: 8px;
      z-index: 2;
      color: #97ff72;
      text-align: center;
    }
  }
}
</style>
