<template>
  <div
    class="norem_sprites_wrapper"
    :style="{
      left: _left,
      top: _top,
      right: _right,
      bottom: _bottom,
      backgroundPosition: `${_bg_x} ${_bg_y}`,
      transform: `scale(${scaleValue})`,
    }"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  ></div>
</template>

<script setup>
import { propValueUnificationString } from "@/utils/utils";
import { computed, ref } from "vue";

const props = defineProps({
  left: {
    type: [String, Number],
    default: "auto",
  },
  top: {
    type: [String, Number],
    default: "auto",
  },
  right: {
    type: [String, Number],
    default: "auto",
  },
  bottom: {
    type: [String, Number],
    default: "auto",
  },
  bg_x: {
    type: [String, Number],
    default: 0,
  },
  bg_y: {
    type: [String, Number],
    default: 0,
  },
});

const emits = defineEmits(["_click"]);

const _left = computed(() => propValueUnificationString(props.left));
const _top = computed(() => propValueUnificationString(props.top));
const _right = computed(() => propValueUnificationString(props.right));
const _bottom = computed(() => propValueUnificationString(props.bottom));
const _bg_x = computed(() => propValueUnificationString(props.bg_x));
const _bg_y = computed(() => propValueUnificationString(props.bg_y));

const scaleValue = ref(1);
const handleTouchStart = () => {
  scaleValue.value = 1.2;
};
const handleTouchEnd = () => {
  scaleValue.value = 1;
  emits("_click");
};

const handleClick = () => {
  emits("_click");
};
</script>

<style lang="scss" scoped>
.norem_sprites_wrapper {
  position: absolute;
  width: 52px;
  height: 52px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/sprites.png");
  background-size: auto 52px;
  background-repeat: no-repeat;
  z-index: 5;
}
</style>
