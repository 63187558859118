<template>
  <div
    class="card_back_wrapper"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <NavBar title="卡券" />

    <div class="user_info_wrapper">
      <div class="user_info">
        <div class="info">
          <div class="head">
            <HeadPortrait
              :level="Number(store.userInfo.recharge_level)"
              :src="store.userInfo.headimgurl"
              width="100%"
              height="100%"
              :ae_id="store.userInfo.select_title"
              ae
              fe
            />
          </div>
          <div class="user_name">{{ store.userInfo.player_name }}</div>
        </div>
        <div class="lottery_wrapper">
          {{ addCommas(store.userInfo.lottery) }}
        </div>
        <div class="gold_wrapper">
          {{ addCommas(store.userInfo.gold) }}
        </div>
      </div>
    </div>

    <div class="tabs_wrapper">
      <van-config-provider :theme-vars="tabsVars">
        <van-tabs v-model:active="currentCardType" @change="handleTabChange">
          <van-tab
            v-for="cardTypeItem in cardTypeList"
            :title="cardTypeItem.name"
            :name="cardTypeItem.type"
          >
            <div class="card_list_wrapper">
              <div
                :class="[
                  'card_list_item',
                  cardItem.type == 'jifen_card' && 'jifen_item',
                  cardItem.type == 'jiacheng_card' && 'jiacheng_item',
                  cardItem.type == 'tianti_buff_card' && 'jifen_item',
                  cardItem.type == 'zhekou_card' && 'zhekou_item',
                ]"
                v-for="cardItem in store.cardBagData[cardTypeItem.type]"
              >
                <div class="used_mask" v-if="cardTypeItem.type == 'expired'">
                  <div class="used_text">已过期</div>
                </div>

                <div
                  class="used_mask"
                  v-if="
                    cardTypeItem.type == 'using' &&
                    usingTianti_key != cardItem.item_id
                  "
                >
                  <div class="used_text">已使用</div>
                </div>

                <div class="card_name">
                  {{ cardItem.card_use_name }}
                </div>

                <div
                  class="use_card_btn"
                  :style="{
                    backgroundColor:
                      cardItem.type == 'zhekou_card'
                        ? '#DDBBFF'
                        : cardItem.type == 'jiacheng_card'
                        ? '#C4FAFA'
                        : '#FBF14E',
                  }"
                  @click="go_to_use_card(cardItem)"
                >
                  {{
                    cardTypeItem.type == "using"
                      ? usingTianti_key == cardItem.item_id
                        ? "生效中"
                        : "已使用"
                      : "立即使用"
                  }}
                </div>

                <div
                  class="explain_icon"
                  :style="{
                    backgroundColor:
                      cardItem.type == 'zhekou_card'
                        ? '#C4BAFF'
                        : cardItem.type == 'jiacheng_card'
                        ? '#4E38F8'
                        : '#F8983C',
                  }"
                  @click="card_explain_show_toggle(true)"
                >
                  使用说明
                </div>
                <div
                  class="card_end_time"
                  v-if="
                    cardItem.type == 'tianti_buff_card' &&
                    usingTianti_key == cardItem.item_id
                  "
                >
                  加成时间：{{ cur_using_tianti_card_time }}
                </div>
                <div
                  class="card_end_time"
                  v-if="cardItem.type != 'tianti_buff_card'"
                >
                  起效时间：{{ cardItem.start_time }}
                </div>
                <div
                  class="card_end_time"
                  v-if="cardTypeItem.type == 'waitting'"
                >
                  过期时间：{{ cardItem.end_time }}
                </div>
              </div>

              <div
                class="nocard"
                v-if="store.cardBagData[cardTypeItem.type].length == 0"
              >
                暂无数据
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </van-config-provider>
    </div>

    <van-popup v-model:show="card_explain_show" style="background: none">
      <div class="card_explain">
        <div class="close_icon" @click="card_explain_show_toggle(false)"></div>
        <van-image
          width="100%"
          height="auto"
          :show-loading="false"
          :src="recharge_card_explain"
        />
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { useStore } from "@/store/store";
import { addCommas } from "@/utils/utils";
import { reactive, onBeforeMount, computed, ref } from "vue";

import NavBar from "@/components/NavBar.vue";
import HeadPortrait from "@/components/HeadPortrait.vue";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
// import Level from "@/components/Level.vue";
import { useCountDown } from "@vant/use";
import recharge_card_explain from "@/assets/images/recharge_card_explain.png";
import { showToast } from "vant";

const store = useStore();

const tabsVars = reactive({
  tabsNavBackground: "none",
  tabTextColor: "#fff",
  tabActiveTextColor: "#FAEF85",
  tabsBottomBarColor: "#FAEF85",
  tabsLineHeight: "35px",
  tabFontSize: "16px",
});

const cur_using_tianti_card_time = computed(() => {
  // <span>剩余小时：{{ current.hours }}</span>
  // <span>剩余分钟：{{ current.minutes }}</span>
  // <span>剩余秒数：{{ current.seconds }}</span>
  // <span>剩余毫秒：{{ current.milliseconds }}</span>

  let hh =
    aa.current.value.hours < 10
      ? "0" + aa.current.value.hours
      : aa.current.value.hours;
  let mm =
    aa.current.value.minutes < 10
      ? "0" + aa.current.value.minutes
      : aa.current.value.minutes;
  let ss =
    aa.current.value.seconds < 10
      ? "0" + aa.current.value.seconds
      : aa.current.value.seconds;

  return `${hh}:${mm}:${ss}`;
});

const card_explain_show = ref(false);

const card_explain_show_toggle = (type) => {
  card_explain_show.value = type;
};

const currentCardType = ref("waitting");
const cardTypeList = reactive([
  {
    type: "waitting",
    name: "未使用",
  },
  {
    type: "using",
    name: "已使用",
  },
  {
    type: "expired",
    name: "已过期",
  },
]);

const aa = useCountDown({
  time: 3000,
  onFinish() {
    sendInstructions({
      msg_type: "get_item",
    });
  },
});

const usingTianti_key = ref(0);

const handleTabChange = (evt, evt2) => {
  if (evt == "using") {
    // const caa =

    store.cardBagData.using.some((item) => {
      if (
        item.type == "tianti_buff_card" &&
        item.buff_end_time * 1000 > Date.now()
      ) {
        usingTianti_key.value = item.item_id;

        aa.reset(item.buff_end_time * 1000 - Date.now());
        aa.start();
        return true;
      } else return false;
    });
  }
};

const go_to_use_card = async (item) => {
  if (currentCardType.value != "waitting") return;

  if (item.type == "tianti_buff_card") {
    const res = await asyncSendInstructions({
      msg_type: "player_use_item",
      item_id: item.item_id,
    });

    if (res.error == "ok") {
      showToast({
        type: "success",
        message: "使用成功",
      });
      sendInstructions({
        msg_type: "get_item",
      });
    } else {
      showToast({
        type: "text",
        message: "当前已有BUFF生效中\n结束后才可使用",
      });
    }
    return;
  }

  store.payPupToggle(true);
};

onBeforeMount(() => {
  sendInstructions({
    msg_type: "get_item",
  });
});
</script>

<style lang="scss" scoped>
.card_back_wrapper {
  height: 100vh;

  .user_info_wrapper {
    padding: 0 15px;
    // height: 28px;
    margin-bottom: 10px;
    .user_info {
      display: flex;
      margin-top: 14px;
      align-items: center;
      .info {
        margin-right: auto;
        display: flex;
        .head {
          width: 28px;
          height: 28px;
        }
        .user_name {
          font-size: 14px;
          padding-left: 8px;
          line-height: 28px;
          font-weight: bold;
        }
      }

      .lottery_wrapper,
      .gold_wrapper {
        position: relative;
        min-width: 70px;
        text-align: right;
        padding: 0 6px 0 22px;
        height: 20px;
        background-color: #00000040;
        border-radius: 10px;
        margin-left: 6px;
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: -2px;
          width: 20px;
          height: 20px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .lottery_wrapper {
        &::after {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
        }
      }
      .gold_wrapper {
        &::after {
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
        }
      }
    }
  }

  .tabs_wrapper {
    margin-top: 20px;
    flex: 1;
    overflow: auto;
    &:deep(.van-config-provider) {
      height: 100%;
    }

    &:deep(.van-tabs) {
      height: 100%;

      .van-tabs__content {
        height: calc(100% - 35px);
        & > div {
          height: 100% !important;
        }
      }
    }

    .card_list_wrapper {
      padding: 20px 12px;
      background-color: #6e23a2;
      min-height: 79.5vh;
      .card_list_item {
        position: relative;
        padding: 14px;
        color: #000;
        height: 120px;
        margin-top: 10px;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .used_mask {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.5);
          left: 0;
          top: 0;
          z-index: 10;

          .used_text {
            position: absolute;
            right: 35px;
            top: 50%;
            transform: translateY(-50%) rotate(-30deg);
            width: 84px;
            height: 84px;
            line-height: 84px;
            font-size: 16px;
            text-align: center;
            font-weight: bold;
            border-radius: 42px;
            color: #fff;
            background-color: rgba($color: #a4a4a4, $alpha: 0.5);
          }
        }

        .card_name {
          color: #fff;
          font-size: 24px;
          text-shadow: 0px 4px 2px #1e189a;
          font-weight: 800;
        }

        .explain_icon {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 68px;
          height: 24px;
          border-radius: 2px;
          line-height: 24px;
          text-align: center;
          // font-weight: 800;
          color: #fff;
        }

        .card_end_time {
          margin-top: 4px;
          font-size: 12px;
        }

        .use_card_btn {
          position: absolute;
          top: 22px;
          right: 15px;
          width: 84px;
          height: 28px;
          box-shadow: 0px 0px 5px 1px rgba($color: #000000, $alpha: 0.5);
          font-size: 12px;
          line-height: 28px;
          border-radius: 14px;
          text-align: center;
        }
      }

      .nocard {
        height: 100%;
        line-height: 300px;
        color: #fff;
        font-size: 18px;
        text-align: center;
      }
      .jifen_item {
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/jifen_card.png");
      }
      .jiacheng_item {
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/jiacheng_card.png");
      }
      .zhekou_item {
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pay/zhekou_card.png");
      }
    }
  }

  .card_explain {
    position: relative;
    width: 280px;

    .close_icon {
      position: absolute;
      right: 2px;
      top: 2px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 32px;
      height: 32px;
      z-index: 3;
    }
  }
}
</style>
