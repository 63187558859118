<template>
  <div
    class="welfare_lobby_wrapper"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <NavBar />

    <div class="container">
      <div class="code_list_wrapper">
        <div
          class="code_item"
          v-for="codeItem in codelist.sort(
            (a, b) => Number(a.id) - Number(b.id)
          )"
        >
          <div class="level_text_icon" v-if="codeItem.level > 1">
            {{ codeItem.level_name }} 及以上
          </div>

          <div class="code_item_cotent">
            <div class="gold_icon_wrapper">
              <van-image
                width="100%"
                height="100%"
                fit="fill"
                :src="codeItem.level > 1 ? gold_icon2 : gold_icon1"
              />
            </div>

            <div class="code_item_info">
              <p class="num">{{ codeItem.gold }}金币</p>
              <div class="code_time_value">
                {{ `${codeItem.stratTime} 开始` }}
              </div>
              <div class="code_time_value">
                {{ `${codeItem.endTime} 过期` }}
              </div>
            </div>
            <div class="code_item_btn" @click="getChangeCodeAward(codeItem)">
              <van-image
                width="100%"
                height="100%"
                fit="fill"
                :src="btnimgs[codeItem.statu]"
              />
            </div>
          </div>

          <!-- <div class="code_gold_value">
            <span class="number">{{ codeItem.gold }}</span>
            <span> 金币</span>
          </div>
          <div class="code_time_value">
            {{ `${codeItem.stratTime} 开始` }}
          </div>
          <div class="code_time_value">
            {{ `${codeItem.endTime} 过期` }}
          </div>

          <div class="get_btn">
            <div
              class="get_btn_0"
              v-if="codeItem.statu == 0"
              @click="getChangeCodeAward(codeItem)"
            >
              点击领取
            </div>
            <div class="get_btn_1" v-if="codeItem.statu == 1">未开始</div>
            <div class="get_btn_2" v-if="codeItem.statu == 2">已过期</div>
          </div>

          <div class="done_statu" v-if="codeItem.statu == 3"></div> -->
        </div>
      </div>

      <div class="welfare_lobby_msg_wrapper">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          :src="welfare_lobby_msg"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { lobbyCode, isExchange, changeAwardSend } from "@/api";
import { onBeforeMount, onMounted, ref } from "vue";
import { formatTimestamp } from "@/utils/utils";

import welfare_lobby_title_1 from "@/assets/images/welfare_lobby_title_1.png";
import NavBar from "@/components/NavBar.vue";

const welfare_lobby_msg =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/welfare_lobby_msg.png";

const gold_icon1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png";
const gold_icon2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/gold_icon_2.png";

const btn0 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/get_btn.png";
const btn1 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/inactive.png";
const btn2 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/timer_out.png";
const btn3 =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/done_btn.png";

import { gradLevelIcon } from "@/utils/levelData";
import { showToast } from "vant";

// const router = useRouter();
const store = useStore();

const codelist = ref([]);

const btnimgs = [btn0, btn1, btn2, btn3];

const getListData = async () => {
  const res = await lobbyCode();

  if (res.data) {
    res.data.forEach(async (item) => {
      const stratTime = formatTimestamp(item.start_time, "mm-dd hh:mi");
      const endTime = formatTimestamp(item.end_time, "mm-dd hh:mi");

      const codeItem = {
        id: item.id,
        gold: item.gold,
        code: item.code,
        stratTime: stratTime,
        endTime: endTime,
        statu: item.type,
        level: Number(item.level),
        level_name:
          gradLevelIcon[Number(item.level) - 1 < 0 ? 0 : Number(item.level) - 1]
            .level_text,
      };

      const statu = await isExchange({
        uid: parseInt(store.userInfo.uid),
        code: item.code,
      });

      statu.is_exchange && (codeItem.statu = 3);

      codelist.value.push(codeItem);
    });
  } else {
    const today = new Date();
    const month = today.getMonth() + 1; // 月份从0开始，所以要加1
    const day = today.getDate();

    const defultData = [
      {
        id: 1,
        gold: 88,
        code: "cctv",
        stratTime: `${month < 10 ? "0" + month : month}-${day}  00:00`,
        endTime: `${month < 10 ? "0" + month : month}-${day}  00:00`,
        statu: 2,
        level: 0,
      },
      {
        id: 2,
        gold: 188,
        code: "cctva",
        stratTime: `${month < 10 ? "0" + month : month}-${day}  00:00`,
        endTime: `${month < 10 ? "0" + month : month}-${day}  00:00`,
        statu: 2,
        level: 2,
      },
    ];

    codelist.value = defultData;
  }
};

const getChangeCodeAward = async (codeItem) => {
  if (store.userInfo.recharge_level < codeItem.level) {
    showToast({
      type: "fail",
      message: `等级不足`,
    });

    return;
  }

  if (codeItem.statu !== 0) return;

  const res = await changeAwardSend({
    uid: parseInt(store.userInfo.uid),
    code: codeItem.code,
  });

  if (res.errorCode == 0 || res.errorMsg == "兑换成功") {
    // showToast({
    //     type:"success"
    // })
    codelist.value.some((item) => {
      if (item.code == codeItem.code) {
        item.statu = 3;
        return true;
      } else return false;
    });
  }
};

onBeforeMount(() => {
  getListData();
});
</script>

<style lang="scss" scoped>
.welfare_lobby_wrapper {
  min-height: 100vh;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/top_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #251c41;
  .nav_bar_wrapper {
    position: relative;
    height: 40px;

    .nav_bar_text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 96px;
      height: 36px;
      background-size: 100% auto;
      background-image: url("@/assets/images/payment_cente_title.png");
      background-repeat: no-repeat;
    }

    .nav_bar_back_wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 80px;
      height: 40px;
      background-size: 100% 100%;
      background-image: url("@/assets/images/navbar_back_icon.png");
      background-repeat: no-repeat;
    }
  }

  .container {
    padding-top: 168px;
    padding-bottom: 20px;
    .title {
      width: 124px;
      margin: 0 auto;
    }

    .code_list_wrapper {
      display: flex;
      flex-wrap: wrap;

      padding: 20px 14px;

      background-color: #231b66;
      border-radius: 10px;
      border: 1px solid #8dc5fc;
      .code_item {
        position: relative;
        margin-top: 10px;
        width: 100%;
        height: 96px;

        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/welfare/code_item_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .level_text_icon {
          position: absolute;
          top: 0;
          left: 0;
          border-top-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 2px 10px;
          background: linear-gradient(90deg, #240c63 0%, #000000 100%);
          color: #fff;
          font-size: 10px;
          text-align: center;
        }

        .code_item_cotent {
          display: flex;
          padding: 0 18px;
          height: 100%;
          // justify-content: center;
          align-items: center;
          .gold_icon_wrapper {
            width: 50px;
            height: 50px;
          }

          .code_item_info {
            flex: 1;
            height: 60px;

            padding-left: 12px;
            .num {
              font-size: 20px;
              font-weight: 800;
            }

            .code_time_value {
              font-size: 10px;
              color: #ffffff60;
            }
          }

          .code_item_btn {
            width: 84px;
            height: 30px;
            // background-color: skyblue;
          }
        }
      }
    }

    .welfare_lobby_msg_wrapper {
      margin-top: 12px;
    }
  }
}
</style>
