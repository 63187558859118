<template>
  <div
    class="taskCenter"
    :style="{
      paddingTop: store.areaInsetTopValue + 'px',
    }"
  >
    <NavBar />

    <div class="c_container">
      <div class="task_block_wrapper">
        <div class="task_tab_wrapper">
          <div class="task_tab">
            <div
              :class="[
                'task_tab_item',
                curTaskType == tabItem.type ? 'task_tab_item_active' : '',
              ]"
              v-for="tabItem in taskTypeTabList"
              :key="tabItem.type"
              @click="taskTabChange(tabItem.type)"
            >
              <span style="position: relative; z-index: 2">{{
                tabItem.text
              }}</span>
            </div>
          </div>
          <div class="tab_active_block" ref="tabActiveBlock"></div>
        </div>
        <div class="all_day_task_wrapper" v-if="curTaskType == 'day'">
          <div class="all_day_task_left">
            <div class="task_text">完成所有今日任务</div>
            <div class="task_reward">
              <div class="task_reward_text">完成奖励：</div>
              <div class="task_reward_icon">
                <van-image
                  width="100%"
                  height="auto"
                  block
                  :src="ticket_icon"
                />
              </div>
              <div class="task_reward_value">+6666</div>
            </div>
          </div>

          <div class="all_day_task_right">
            <div
              class="all_day_task_get_btn"
              v-if="initTaskData[0].isAllDone == 'get'"
              @click="getAllDayAward"
            >
              领取
            </div>
            <div
              class="all_day_task_undone_btn"
              v-if="initTaskData[0].isAllDone == 'undone'"
              @click="handleGoToTaskBtn('0')"
            >
              去完成
            </div>

            <div
              class="all_day_task_done_btn"
              v-if="initTaskData[0].isAllDone == 'done'"
            >
              已领取
            </div>

            <div class="all_day_aim">
              {{
                `${initTaskData[0].taskDoneCount}/${initTaskData[0].taskCount}`
              }}
            </div>
          </div>
        </div>

        <div class="get_tiaozhan_card" v-if="curTaskType == 'day'">
          <div class="left">
            <div class="text">每日领取挑战卡</div>
            <div class="tz_icon">
              <van-image width="100%" height="auto" block :src="icon_card" />
            </div>

            <span style="color: #fee22a">+{{ initTaskData[0].tzCardNum }}</span>
          </div>
          <div class="right">
            <div
              class="get_tz_card_btn"
              v-if="initTaskData[0].tzCardIsGet == 'get'"
              @click="handleGetTaskAward(initTaskData[0].tzCardTaskId)"
            >
              领取
            </div>
            <div class="done_tz_card_btn" v-else>已领取</div>
          </div>
        </div>

        <div class="task_list_wrapper">
          <div
            class="task_list_card"
            v-for="taskTypeItem in initTaskData[
              curTaskType == 'day' ? 0 : curTaskType == 'week' ? 1 : 2
            ].taskList"
            :key="taskTypeItem.taskTypes[0]"
          >
            <div class="task_card_title">
              {{ taskTypeItem.title }}
            </div>
            <div class="task_card_list">
              <div
                class="task_item"
                v-for="taskItem in taskTypeItem.taskList"
                :key="taskItem.taskID"
              >
                <div class="task_left">
                  <div class="task_content_text">
                    {{ taskItem.taskName }}
                  </div>
                  <div class="task_reward">
                    <div class="task_reward_text">完成奖励：</div>
                    <div class="task_reward_icon">
                      <van-image
                        width="100%"
                        height="auto"
                        block
                        :src="ticket_icon"
                        v-if="taskItem.awardType == 'lottery'"
                      />

                      <van-image
                        width="100%"
                        height="auto"
                        block
                        :src="gold_icon"
                        v-if="taskItem.awardType == 'gold'"
                      />
                      <van-image
                        width="100%"
                        height="auto"
                        block
                        :src="dashi_icon"
                        v-if="
                          taskItem.awardType == 'dashi' ||
                          taskItem.awardType == 'dashi_mul'
                        "
                      />
                    </div>
                    <div class="task_reward_number">
                      +{{ taskItem.awardNumber }}
                      <span
                        v-if="
                          taskItem.awardType == 'dashi' ||
                          taskItem.awardType == 'dashi_mul'
                        "
                        >大师分</span
                      >
                    </div>
                  </div>
                </div>
                <div class="task_right">
                  <div class="width absolute-center">
                    <template v-if="curTaskType != 'login'">
                      <div
                        class="task_btn"
                        v-if="taskItem.statu == 'undone'"
                        @click="handleGoToTaskBtn(taskTypeItem.taskTypes[0])"
                      >
                        去完成
                      </div>
                      <div
                        class="task_get_btn"
                        v-if="taskItem.statu == 'get'"
                        @click="handleGetTaskAward(taskItem.taskID)"
                      >
                        领取
                      </div>
                      <div class="task_ed_btn" v-if="taskItem.statu == 'done'">
                        已完成
                      </div>
                      <div class="task_aim" v-if="taskItem.taskType != 'card'">
                        {{ `${taskItem.current}/${taskItem.complete}` }}
                      </div>
                      <div class="task_aim" v-else>
                        <!-- {{ `持续时间${taskItem.complete}天` }} -->
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="task_get_btn"
                        v-if="taskItem.statu == 'get'"
                        @click="handleGetTaskAward(taskItem.taskID)"
                      >
                        领取
                      </div>
                      <div
                        class="task_ed_btn"
                        v-else-if="taskItem.statu == 'done'"
                      >
                        已完成
                      </div>
                      <div class="task_aim" v-else>
                        {{ `${taskItem.current}/${taskItem.complete}` }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, onBeforeMount } from "vue";
import { useStore } from "@/store/store";
import NavBar from "@/components/NavBar.vue";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";

import { initTaskPageData } from "@/utils/utils";

import { showToast, closeToast } from "vant";

const router = useRouter();
const store = useStore();

const gold_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png";
const ticket_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png";
const dashi_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/dashi_icon.png";
const login_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/login_icon.png";

const type_game =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/type_game.png";
// const share_icon = "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/share_icon.png";
const game_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/game_icon.png";
const recharge_icon =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/game_1.png";

const icon_card =
  "https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/icon_card.png";

const initTaskData = ref([
  {
    title: "每日任务",
    taskType: "day",
    isAllDone: "undone",
    taskCount: 0, // 日任务数量
    taskDoneCount: 0, // 完成的日任务数量
    tzCardNum: 0, // 挑战卡数量
    tzCardTaskId: "103500",
    tzCardIsGet: false,
    taskList: [
      {
        title: "充值任务",
        taskTypes: ["recharge"],
        taskList: [],
      },
      {
        title: "金币任务",
        taskTypes: ["cost_gold"],
        taskList: [],
      },
      {
        title: "彩劵任务",
        taskTypes: ["lottery"],
        taskList: [],
      },
      {
        title: "比赛任务",
        taskTypes: ["mgc_tz_win", "bbl_tz_win", "mgc_tz", "bbl_tz"],
        taskList: [],
      },
      {
        title: "游戏任务",
        taskTypes: ["mgc_game", "bbl_game", "mxt_game"],
        taskList: [],
      },
    ],
  },
  {
    title: "每周任务",
    taskType: "week",
    taskList: [
      {
        title: "充值任务",
        taskTypes: ["recharge"],
        taskList: [],
      },
      {
        title: "金币任务",
        taskTypes: ["cost_gold"],
        taskList: [],
      },
      {
        title: "彩劵任务",
        taskTypes: ["lottery"],
        taskList: [],
      },
      {
        title: "比赛任务",
        taskTypes: ["mgc_tz_win", "bbl_tz_win", "mgc_tz", "bbl_tz"],
        taskList: [],
      },
      {
        title: "游戏任务",
        taskTypes: ["mgc_game", "bbl_game", "mxt_game"],
        taskList: [],
      },
    ],
  },
  {
    title: "登录任务",
    taskType: "login",
    currentLoginDays: 0,
    taskList: [],
  },
]);
const getTaskInfoData = async () => {
  showToast({
    type: "loading",
    forbidClick: true,
    duration: 0,
  });

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_task_info",
    });

    if (res.task_info) {
      initTaskData.value = initTaskPageData(res);
      if (store.cardTaskList.length > 0) {
        const taskList = JSON.parse(
          JSON.stringify(initTaskData.value[0].taskList[0].taskList)
        );

        store.cardTaskList.forEach((cardTask) => {
          const fIndex = taskList.findIndex(
            (taskItem) => taskItem.taskID == cardTask.taskID
          );

          if (fIndex !== -1) {
            taskList[fIndex] = cardTask;
          } else taskList.push(cardTask);
        });

        initTaskData.value[0].taskList[0].taskList = taskList;
      }
    }
  } catch (error) {
    // 处理错误
  }

  closeToast();
};

const curTaskType = ref("day");
const taskTypeTabList = [
  {
    text: "每日任务",
    type: "day",
  },
  {
    text: "每周任务",
    type: "week",
  },
  {
    text: "登录任务",
    type: "login",
  },
];
const tabActiveBlock = ref(null);
const taskTabChange = (tabType) => {
  curTaskType.value = tabType;
  tabType == "day" &&
    tabActiveBlock.value &&
    (tabActiveBlock.value.style.left = "0%");
  tabType == "week" &&
    tabActiveBlock.value &&
    (tabActiveBlock.value.style.left = "33.33%");
  tabType == "login" &&
    tabActiveBlock.value &&
    (tabActiveBlock.value.style.left = "66.67%");
};

const handleGoToTaskBtn = (type) => {
  if (type == "mgc_tz_win") {
    router.replace("/challenge");
  } else router.replace("/home");
};

const handleGetTaskAward = async (taskId) => {
  showToast({
    type: "loading",
    duration: 0,
    forbidClick: true,
  });

  // 周卡月卡领取
  if (taskId.split("_").length > 1) {
    const index = taskId.split("_")[1];

    try {
      closeToast();
      const _res = await asyncSendInstructions({
        msg_type: "get_recharge_card_reward",
        index: index,
      });

      if (_res.error == "ok") {
        showToast({
          type: "success",
          message: "领取成功",
        });
        store.$patch((state) => {
          state.userInfo.gold = Number(state.userInfo.gold) + Number(_res.gold);
        });

        initTaskData.value[0].taskList[0].taskList.some((item) => {
          if (item.taskID == taskId) {
            item.statu = "done";
            return true;
          } else return false;
        });
      }
    } catch (error) {
      console.log("error--->", error);
      closeToast();
    }

    return;
  }

  const params = {
    msg_type: "get_task_reward",
    task_id: taskId,
  };
  try {
    closeToast();
    const res = await asyncSendInstructions(params);

    if (res.error != "ok") {
      showToast({
        type: "fail",
        message: "领取失败！",
      });

      return;
    }

    const _task_id = res.task_id;
    const _key =
      res.reward.type == "tz_card" ? "tiaozhan_ticket" : res.reward.type;
    store.$patch((state) => {
      const _value = Number(res.reward.num);
      state.userInfo[_key] = state.userInfo[_key] + _value;
    });

    if (_key == "tiaozhan_ticket") {
      initTaskData.value[0].tzCardIsGet = false;
      showToast({
        type: "success",
        message: "领取成功！",
        forbidClick: true,
        onClose() {},
      });
      return;
    }

    initTaskData.value.some((taskType) => {
      return taskType.taskList.some((taskItem) => {
        return taskItem.taskList.some((task) => {
          if (task.taskID == _task_id) {
            task.statu = "done";
            return true;
          } else return false;
        });
      });
    });

    showToast({
      type: "success",
      message: "领取成功！",
      forbidClick: true,
      onClose() {},
    });
  } catch (error) {
    // 处理错误
    console.log("errorrrrrr", error);
    closeToast();
  }
};

const getAllDayAward = async () => {
  showToast({
    type: "loading",
    duration: 0,
    forbidClick: true,
  });

  try {
    const res = await asyncSendInstructions({
      msg_type: "get_all_task_complete_reward",
    });

    closeToast();
    if (res.error != "ok") {
      showToast({
        type: "fail",
        message: "领取失败！",
      });
      return;
    }
    showToast({
      type: "success",
      message: "领取成功！",
      forbidClick: true,
      onClose() {
        sendInstructions(
          {
            msg_type: "get_player_common",
          },
          100
        );
        getTaskInfoData();
      },
    });
  } catch (error) {
    closeToast();
  }
};

onBeforeMount(() => {
  getTaskInfoData();

  if (store.initPayPupData.base.length == 0) {
    sendInstructions(
      {
        msg_type: "get_player_recharge_status",
      },
      50
    );
  }
});

onMounted(() => {});
</script>

<style lang="scss" scoped>
.taskCenter {
  position: relative;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/top_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #171752;
  .c_container {
    padding-top: 130px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .top_title_wrapper {
    background: linear-gradient(135deg, #e2c9e2 0%, #d4edf1 50%, #ffffff 100%);

    font-weight: bold;
    background: linear-gradient(135deg, #e2c9e2 0%, #d4edf1 50%, #ffffff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 2;
    .title {
      font-weight: 800;
      font-size: 32px;
    }
    .tips {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .login_wrapper {
    position: relative;
    z-index: 2;

    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/login_index_bg.png");
    min-height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 12px;
    margin-top: 16px;
    .login_cotent {
      padding: 12px 10px;
      .login_title {
        font-size: 20px;
        font-weight: 800;
        background: linear-gradient(
          180deg,
          #ffe6df 0%,
          #edd2ff 56%,
          #7a7cd8 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
      }

      .login_list {
        display: flex;
        justify-content: space-between;
        .login_list_item {
          // flex: 1;
          width: 9.2%;

          .item_card {
            border-radius: 6px;
            padding: 2px;
            text-align: center;
            background: linear-gradient(90deg, #3a2755 0%, #3e3561 100%);
            .gold_icon_wrapper {
              width: 100%;
              height: auto;
              position: relative;

              .item_card_ed_text {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                text-align: center;
                background: linear-gradient(180deg, #fcebc6 0%, #f2beb4 100%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bold;
                font-size: 8px;
              }
            }
            .reward {
              font-size: 10px;
              color: #fee22a;
              .last_day_reward {
                text-align: center;
                font-size: 10px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 800;

                background: linear-gradient(
                  180deg,
                  #ff928c 0%,
                  #fdfbed 52%,
                  #ff928c 100%
                );
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }

          .item_card_active {
            background: linear-gradient(90deg, #fceec7 0%, #f1bbb3 100%);
            .reward {
              color: #280923;
              font-weight: 1000;
            }
          }

          .item_card_ed {
            background: linear-gradient(90deg, #3a2755 0%, #3e3561 100%);
          }

          .login_day {
            text-align: center;
            margin-top: 8px;
            font-size: 10px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: 1000;
            background: linear-gradient(180deg, #fcebc6 0%, #f2beb4 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .progress_wrapper {
        display: flex;
        margin-top: 20px;
        .progress_text {
          min-width: 20px;
          text-align: center;
          font-size: 12px;
          font-weight: 800;

          background: linear-gradient(
            180deg,
            #ffe6df 0%,
            #edd2ff 56%,
            #7a7cd8 100%
          );
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .progress_dom {
          flex: 1;
          position: relative;

          &:deep(.van-progress__pivot) {
            background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/pivot_icon.png") !important;
            background-size: 100% 100% !important;
            background-repeat: no-repeat !important;
            background-color: transparent !important;
            width: 20px;
            height: 20px;
            min-width: 10px;
            border-radius: 50%;
            color: transparent;
          }
        }
      }
    }
  }

  .task_block_wrapper {
    margin-top: 20px;

    .task_tab_wrapper {
      height: 40px;
      border-radius: 18px;
      position: relative;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/tab_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .task_tab {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;

        .task_tab_item {
          flex: 1;
          line-height: 40px;
          text-align: center;
          color: #f4e7cf;
        }

        .task_tab_item_active {
          color: #c97b3c;
          font-weight: 800;
        }
      }

      .tab_active_block {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 33.33%;
        background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank/active_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transition: left 0.3s;
        z-index: 1;
      }
    }

    .all_day_task_wrapper {
      width: 100%;
      margin-top: 10px;
      background-image: url("@/assets/images/all_task_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 94px;
      padding: 30px 16px 20px 16px;

      display: flex;

      .all_day_task_left {
        flex: 1;
        min-height: 50px;
        .task_text {
          font-size: 14px;
          font-weight: 800;
        }
        .task_reward {
          padding-top: 8px;
          display: flex;
          // .task_reward_text {
          // }
          .task_reward_icon {
            width: 20px;
            height: 20px;
          }
          .task_reward_value {
            margin-left: 4px;
            color: #fee22a;
            font-size: 14px;
            font-weight: 800;
          }
        }
      }
      .all_day_task_right {
        position: relative;
        width: 80px;
        min-height: 50px;

        .all_day_task_get_btn {
          position: absolute;
          left: 50%;
          top: 34%;
          transform: translate(-50%, -50%);
          background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
          text-align: center;
          line-height: 28px;
          width: 100%;
          height: 28px;
          color: #280923;
          font-size: 14px;
          border-radius: 14px;
          font-weight: bold;
          opacity: 0.5;
        }

        .all_day_task_undone_btn {
          background: linear-gradient(90deg, #fceec7 0%, #f1bbb3 100%);
          text-align: center;
          line-height: 28px;
          height: 28px;
          color: #280923;
          font-size: 14px;
          border-radius: 14px;
          font-weight: bold;
        }

        .all_day_task_done_btn {
          text-align: center;
          line-height: 28px;
          height: 28px;
          color: #fee22a;
          font-size: 14px;
          border-radius: 14px;
          font-weight: bold;
        }

        .all_day_aim {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .get_tiaozhan_card {
      display: flex;

      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/tz_card_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 10px;
      margin-top: 10px;
      border-radius: 12px;
      justify-content: center;
      align-items: center;
      .left {
        display: flex;
        font-size: 14px;
        font-weight: bold;
        flex: 1;
        .text {
          margin-right: 6px;
        }
        .tz_icon {
          width: 20px;
          height: 20px;
        }
      }
      .right {
        width: 80px;
        height: 28px;

        .get_tz_card_btn {
          background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
          text-align: center;
          line-height: 28px;
          height: 28px;
          color: #280923;
          font-size: 14px;
          border-radius: 14px;
          font-weight: bold;
        }

        .done_tz_card_btn {
          text-align: center;
          line-height: 28px;
          height: 28px;
          color: #fee22a;
          font-size: 14px;
          border-radius: 14px;
          font-weight: bold;
        }
      }
    }

    .task_list_wrapper {
      // padding-top: 20px;
      .task_list_card {
        margin-top: 10px;
        .task_card_title {
          position: relative;
          height: 40px;
          background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/task_card_title.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          color: #fff0bf;
          font-size: 16px;
          line-height: 50px;
          text-align: center;
        }

        .task_card_list {
          background-color: #231e80;
          padding: 10px 15px 20px 15px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          .task_item {
            display: flex;
            margin-top: 10px;
            .task_left {
              flex: 1;
              // max-width:1 ;
              .task_content_text {
                font-size: 14px;
                font-weight: 800;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .task_reward {
                margin-top: 10px;
                display: flex;
                height: 20px;
                line-height: 20px;
                .task_reward_text {
                  font-size: 12px;
                }
                .task_reward_icon {
                  width: 20px;
                  height: 20px;
                }
                .task_reward_number {
                  color: #fee22a;
                  font-size: 14px;
                  font-weight: 800;
                }
              }
            }
            .task_right {
              position: relative;
              min-width: 74px;
              .task_btn {
                background: linear-gradient(90deg, #fceec7 0%, #f1bbb3 100%);
                text-align: center;
                line-height: 28px;
                height: 28px;
                color: #280923;
                font-size: 14px;
                border-radius: 14px;
                font-weight: bold;
              }

              .task_get_btn {
                background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
                text-align: center;
                line-height: 28px;
                height: 28px;
                color: #280923;
                font-size: 14px;
                border-radius: 14px;
                font-weight: bold;
              }

              .task_ed_btn {
                text-align: center;
                line-height: 28px;
                height: 28px;
                color: #fee22a;
                font-size: 14px;
                border-radius: 14px;
                font-weight: bold;
              }
              .task_aim {
                margin-top: 6px;
                text-align: center;
                text-wrap: nowrap;
                white-space: nowrap;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

.task_lottery_award_number {
  display: inline-block;
  color: #ff7f00;
  background-color: #221c2b;
  border-radius: 22px;
  position: relative;
  padding-left: 22px;
  padding-right: 4px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;

  &::after {
    content: "";
    position: absolute;
    left: -2px;
    top: 0;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
  }
}

.task_gold_award_number {
  display: inline-block;
  color: #ff7f00;
  background-color: #221c2b;
  border-radius: 22px;
  position: relative;
  padding-left: 22px;
  padding-right: 4px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;

  &::after {
    content: "";
    position: absolute;
    left: -2px;
    top: 0;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
  }
}

.task_dashi_award_number {
  display: inline-block;
  color: #ff7f00;
  background-color: #221c2b;
  border-radius: 22px;
  position: relative;
  padding-left: 22px;
  padding-right: 4px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  &::after {
    content: "";
    position: absolute;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/task/icon_dashi_small.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 14px;
    width: 20px;
  }
}
</style>
