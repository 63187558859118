<template>
  <transition :name="_Transition_Name">
    <div
      class="my_popup_wrapper"
      v-show="props.show"
      :style="{
        zIndex: props.zIndex,
      }"
    >
      <div class="my_mask" v-if="props.mask"></div>
      <div class="my_popup_cotent">
        <Landscape>
          <slot></slot>
        </Landscape>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { watch, onBeforeUnmount } from "vue";
import Landscape from "@/components/Landscape.vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: "center",
  },
  zIndex: {
    type: [String, Number],
    default: 2000,
  },
  mask: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(["open", "close"]);

const _Transition_Name = {
  center: "van-fade",
  top: "van-slide-up",
  bottom: "van-slide-down",
  left: "van-slide-left",
  right: "van-slide-right",
}[props.position];

watch(
  () => props.show,
  (newV) => {
    if (newV) {
      document.body.style.height = "100vh";
      document.body.style.overflow = "hidden";

      emits("open");
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "visible";
      emits("close");
    }
  }
);

onBeforeUnmount(() => {
  if (document.body.style.overflow === "hidden") {
    document.body.style.height = "auto";
    document.body.style.overflow = "visible";
  }
});
</script>

<style scoped>
.my_popup_wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.my_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.my_popup_cotent {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  inset: 0;
  z-index: 2;
}
</style>
