<template>
  <div
    class="mxt_view_wrapper"
    id="rotate_box"
    @click="golbalClickEvent($event)"
  >
    <GameLoding v-if="!isReady" />

    <Player ref="_Player" :landscape="false" @ready-change="readyChange" />
    <!-- 挑战红包 -->
    <LuckRedBag />
    <MenuLc
      ref="menud"
      @menuSoundClick="menuSoundClick"
      @handleExitEvent="handleExitEvent"
      @handle_update="handle_update_player"
      @handle_display="ui_control_show_toggle"
    />

    <div class="wallet_wrapper">
      <UserWallet v-show="ui_control_show" />
      <UserWallet type="lottery" v-show="ui_control_show" />
    </div>

    <!-- <UserGold /> -->
    <GameRoom v-show="ui_control_show" />

    <!-- 时间 -->
    <ServerTime :right="15" :top="80" />

    <!-- 观战计时 -->
    <WatchTime
      :watch_time="Number(store.current_machine.visit_time)"
      @onFinish="exitGameRequest"
      v-if="store.gameIsWatch && isReady && !store.userInfo.is_gm"
    />

    <div class="top_info_wrapper" v-if="!store.gameIsWatch && ui_control_show">
      <div class="user_info_wrapper">
        <div class="user_portrait">
          <div class="head">
            <HeadPortrait
              width="100%"
              height="100%"
              :src="store.userInfo.headimgurl"
              :level="Number(store.userInfo.recharge_level)"
              :ae_id="store.userInfo.select_title"
              ae
              fe
            />
          </div>
        </div>

        <div class="user_name_wrapper">
          <p class="name_key">
            <van-text-ellipsis :content="store.userInfo.player_name" />
          </p>
          <p class="uid_key">UID:&nbsp;{{ store.userInfo.uid }}</p>
        </div>

        <div class="user_grade_wrapper">
          <div class="user_grade_content">
            <Level
              :border="false"
              level_type="recharge_level"
              background="rgba(0,0,0,0.4)"
              :level_value="Number(store.userInfo.recharge_level)"
            />

            <Level
              :border="false"
              background="rgba(0,0,0,0.4)"
              :level_value="Number(store.userInfo.level)"
            />
          </div>
        </div>
      </div>

      <div class="line_localtion">
        <div class="line_localtion_item">
          <div class="head_cover">
            <HeadPortrait
              v-if="lineData.loacltions[0].head != 0"
              width="100%"
              height="100%"
              :src="lineData.loacltions[0].head"
            />
            <van-image
              width="100%"
              height="100%"
              v-else
              block
              :show-loading="false"
              :src="head_phone"
            />
          </div>
          <div class="location_p">1P</div>
        </div>
        <div class="line_localtion_item">
          <div class="head_cover">
            <HeadPortrait
              v-if="lineData.loacltions[1].head != 0"
              width="100%"
              height="100%"
              :src="lineData.loacltions[1].head"
            />
            <van-image
              width="100%"
              height="100%"
              v-else
              block
              :show-loading="false"
              :src="head_phone"
            />
          </div>
          <div class="location_p">2P</div>
        </div>
        <div class="line_localtion_item">
          <div class="head_cover">
            <HeadPortrait
              v-if="lineData.loacltions[2].head != 0"
              width="100%"
              height="100%"
              :src="lineData.loacltions[2].head"
            />
            <van-image
              width="100%"
              height="100%"
              v-else
              block
              :src="head_phone"
            />
          </div>
          <div class="location_p">3P</div>
        </div>
      </div>
    </div>

    <div class="line_reward_block" v-show="ui_control_show">
      <div class="line_reward_content">
        <div class="line_reward_data_item">
          <div class="line_reward_data_item_name" style="color: #00fdff">
            JP1
          </div>
          <div class="line_reward_data_item_value">
            {{ lineData.line_reward_data.jp1 }}
          </div>
        </div>
        <div class="line_reward_data_item">
          <div class="line_reward_data_item_name" style="color: #00ff29">
            JP2
          </div>
          <div class="line_reward_data_item_value">
            {{ lineData.line_reward_data.jp2 }}
          </div>
        </div>
        <div class="line_reward_data_item">
          <div class="line_reward_data_item_name" style="color: #ffc700">
            JP3
          </div>
          <div class="line_reward_data_item_value">
            {{ lineData.line_reward_data.jp3 }}
          </div>
        </div>
        <div class="line_reward_data_item">
          <div class="line_reward_data_item_name" style="color: #ff0000">
            ALL
          </div>
          <div class="line_reward_data_item_value">
            {{ lineData.line_reward_data.quanpan }}
          </div>
        </div>
      </div>
      <div class="lighting_1"></div>
      <div class="lighting_2"></div>
    </div>

    <!-- 占位者 -->
    <div
      class="occupy_info_wrapper"
      v-if="
        store.gameIsWatch &&
        store.current_machine.occupy.length > 0 &&
        ui_control_show
      "
    >
      <div class="user_portrait">
        <HeadPortrait
          width="100%"
          height="100%"
          :src="
            store.current_machine.occupy[0].head ||
            String(store.current_machine.occupy[0].head_frame)
          "
          :level="Number(store.userInfo.recharge_level)"
        />
      </div>
      <div class="user_name">
        {{ decodeBase64String(store.current_machine.occupy[0].player_name) }}
        &nbsp;&nbsp;
        <span style="color: red; font-weight: bold" v-if="store.userInfo.is_gm"
          >UID: {{ store.current_machine.occupy[0].player_id }}</span
        >
      </div>
    </div>
    <!-- 围观者 -->
    <div
      class="visits_list_wrapper"
      v-if="store.current_machine.visits.length > 0 && ui_control_show"
    >
      <div class="visits_text_wrapper">围观：</div>

      <div
        class="visit_item_user_header"
        v-for="(visitsItem, vIndex) in store.current_machine.visits"
        :key="'visits_' + visitsItem.uid"
        :style="{
          transform: `translateX(-${vIndex * 25}%)`,
        }"
      >
        <HeadPortrait
          width="100%"
          height="100%"
          :ae_id="visitsItem.select_title"
          ae
          fe
          :src="visitsItem.head ?? visitsItem.head_frame"
        />
      </div>
    </div>

    <!-- <TopInfo v-if="!store.gameIsWatch && isReady" /> -->

    <!-- 坐下-- -->
    <Seats @callback="handleSeatCallBack" v-if="store.gameIsWatch && isReady" />

    <!-- 控制台 -->
    <div class="tbj_control_wrapper" v-if="!store.gameIsWatch && isReady">
      <div class="num_coin_btn" @click="coin_up_num(1)">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="!coin_status.l"
          :src="upcoin_1"
        />
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="coin_status.l"
          :src="upcoin_1_active"
        />
      </div>
      <div class="auto_coin_btn" @click="handle_auto_btn_click">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="!coin_status.a"
          :src="upcoin_auto"
        />
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="coin_status.a"
          :src="upcoin_auto_active"
        />
      </div>
      <div class="num_coin_btn" @click="coin_up_num(10)">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="!coin_status.r"
          :src="upcoin_10"
        />
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          v-show="coin_status.r"
          :src="upcoin_10_active"
        />
      </div>
    </div>

    <!-- 马戏团高分提示 -->
    <transition name="van-fade">
      <div class="super_lottery_hint" v-show="store.mxtSuperGoloHint">
        <van-image
          width="100%"
          height="auto"
          fit="fill"
          position="center"
          block
          :src="super_gold"
        />
      </div>
    </transition>

    <!-- 马戏团赚的币提示 -->
    <div class="mxt_hint_wrapper">
      <div
        class="mxt_hint_item"
        v-for="item in store.mxtHintDataList"
        :key="item.key"
      >
        +{{ item.lottery }}
      </div>
    </div>

    <!-- 最后几秒倒计时 v-if="mxtNoOperationBackCount <= 6" -->
    <div class="count_down_time_wrapper">
      <div
        class="count_down_time_img"
        v-for="(
          count_down_time_item, count_down_time_index
        ) in count_down_time_arr"
      >
        <transition name="van-fade">
          <van-image
            v-show="mxtNoOperationBackCount - 1 == count_down_time_index"
            width="100%"
            height="auto"
            fit="fill"
            position="center"
            block
            :src="count_down_time_item"
          />
        </transition>
      </div>
    </div>

    <!-- 消息通知 -->
    <MyNoticeBar :top="20" left="auto" right="auto" />

    <!-- <CollectEntrance
      :icon_type="store.current_machine.product_name == 'mxt' ? 'hly' : 'qhy'"
      v-if="ui_control_show"
    />
    <CollectEntrance
      :icon_type="store.current_machine.product_name == 'mxt' ? 'xcb' : 'msb'"
      v-if="ui_control_show"
    /> -->

    <Sprites
      :right="15"
      :top="200"
      :bg_x="-468"
      @_click="collectCardShowToggle(true)"
      v-show="ui_control_show"
    />
    <Sprites
      :right="15"
      :top="260"
      :bg_x="-520"
      @_click="rankPupShowToggle(true)"
      v-show="ui_control_show"
    />

    <WheelEntrance :right="15" :top="320" v-show="ui_control_show" />

    <SprogTaskEntrance
      :left="15"
      :top="380"
      v-show="ui_control_show && store.userInfo.register_time"
    />

    <!-- 马戏团无操作返回 -->
    <div
      class="operation_timer_back_wrapper"
      v-if="!store.gameIsWatch && isReady"
    >
      <van-circle
        :style="{
          position: 'relative',
          zIndex: '2',
        }"
        v-model:current-rate="currentRate"
        rate="100"
        stroke-width="100"
        color="#A1EF5A"
        layer-color="#757A71"
        size="100%"
        text=""
      />
      <div class="operation_timer_bg"></div>
      <div class="operation_timer">
        <span class="timer_number">{{ mxtNoOperationBackCount }}s</span>
      </div>
    </div>

    <!-- 收集提示 -->
    <CollectHint />

    <!-- 推币任务 -->
    <transition name="van-fade">
      <div
        class="norem_task_icon_wrapper"
        v-show="!taskPupShow && !store.gameIsWatch && ui_control_show"
        @click="taskPupShowChange($event, true)"
      >
        <div class="task_hot_icon" v-if="store.gameTaskData.getCount > 0">
          {{ store.gameTaskData.getCount }}
        </div>
      </div>
    </transition>

    <transition name="van-slide-right">
      <div class="task_content_wrapper" v-show="taskPupShow && ui_control_show">
        <div class="task_head_title">
          <div class="task_title_icon"></div>
          推币任务
          <div
            class="task_back_icon"
            @click="taskPupShowChange($event, false)"
          ></div>
        </div>
        <div class="task_container">
          <div class="task_all_wrapper">
            <div class="task_all_task_name">
              {{ store.gameTaskData.allTaskName }}
            </div>
            <div
              class="task_all_task_reward"
              v-if="store.gameTaskData.allTaskStatu == 0"
            >
              <span>奖励:</span>
              <div class="reward_icon">
                <van-image
                  width="100%"
                  height="100%"
                  block
                  :src="ticket_icon"
                />
              </div>
              <span>+{{ store.gameTaskData.allTaskReward }}</span>
            </div>

            <div
              class="task_all_task_get"
              v-else-if="store.gameTaskData.allTaskStatu == 1"
              @click="getGameViewTaskDone(store.gameTaskData.allTaskIndex)"
            >
              领取
            </div>

            <div class="task_all_task_done" v-else>已领取</div>
          </div>

          <div class="task_list_wrapper">
            <div
              class="task_list_item"
              v-for="taskItem in store.gameTaskData.taskList"
            >
              <div class="task_name">{{ taskItem.taskName }}</div>
              <div class="task_reward">
                <span>奖励:</span>
                <div class="reward_icon">
                  <van-image
                    width="100%"
                    height="100%"
                    block
                    :src="ticket_icon"
                  />
                </div>
                <span>+{{ taskItem.reward }}</span>
              </div>
              <div class="task_btn_wrapper">
                <div class="task_num" v-if="taskItem.statu === 0">
                  {{ `${taskItem.currentNumer}/${taskItem.complete}` }}
                </div>
                <div
                  class="task_get_btn"
                  v-else-if="taskItem.statu === 1"
                  @click="getGameViewTaskDone(taskItem.taskIndex)"
                >
                  领取
                </div>
                <div class="task_done" v-else>已领取</div>
              </div>
            </div>
            <div class="task_footer">任务每天0点开始重新计算</div>
          </div>
        </div>
      </div>
    </transition>

    <!-- 雨刷 -->

    <div
      class="norem_wiper_btn_wrapper"
      @touchstart="handleMxtWiperStart"
      @touchend="handleMxtWiperEnd"
      v-if="isReady && !store.gameIsWatch"
    ></div>

    <!-- 设置自动投币速度 -->
    <van-popup
      v-model:show="setAutoSpeedValueShow"
      round
      :close-on-click-overlay="false"
    >
      <div class="logout_wrapper">
        <p class="logout_title">设置自动投币速度</p>
        <div class="logout_content">
          <div class="number_s">
            <div
              class="number_item"
              v-for="autoSpeedItem in 3"
              @click="controlProgress(autoSpeedItem)"
            >
              {{ autoSpeedItem }}次/秒
            </div>
          </div>

          <div
            class="number_bar_wrapper"
            ref="bar_wrappper"
            @touchstart="handleSliderTouchStart"
            @touchmove="handleSliderTouchMove"
            @touchend="handleSliderTouchEnd"
          >
            <div class="slider_active_bar" ref="s_active">
              <div class="slider_block" ref="s_block"></div>
            </div>
            <div class="speed_wrapper">
              <div
                class="speed_item"
                v-for="autoSpeedItem in 3"
                ref="speed_item_d"
                @click="controlProgress(autoSpeedItem)"
              ></div>
            </div>
          </div>
        </div>
        <div class="logout_btns">
          <div class="logout_btn" @click="setAutoSpeedValueShowToggle(false)">
            取消
          </div>
          <div
            class="logout_btn"
            style="
              background-image: linear-gradient(
                90deg,
                #ed81ff 0%,
                #5034ff 100%
              );
            "
            @click="confirmStartAuto"
          >
            开启自动
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 收集 待优化的组件 -->
    <CollectCardPup
      :pupshow="collectCardShow"
      :game_name="store.current_machine.product_name"
      :islandscape="false"
      @pup-close-icon-click="collectCardShowToggle(false)"
    />
    <!-- 排行 待优化的组件 -->
    <PumpkinPup
      :pupshow="rankPupShow"
      :islandscape="false"
      @close-icon-click="rankPupShowToggle(false)"
    />
  </div>
</template>

<script setup>
import Level from "@/components/Level.vue";
import GameLoding from "@/components/GameLoding.vue";
import Player from "@/components/Player.vue";
import MenuLc from "@/components/MenuLc.vue";
import WatchTime from "@/components/WatchTime.vue";
import GameRoom from "@/components/GameRoom.vue";
import HeadPortrait from "@/components/HeadPortrait.vue";
import Seats from "@/components/Seats.vue";
import MyNoticeBar from "@/components/MyNoticeBar.vue";
// import TopInfo from "@/components/TopInfo.vue";
import LuckRedBag from "@/components/LuckRedBag.vue";
import WheelEntrance from "@/components/WheelEntrance.vue";
import SprogTaskEntrance from "@/components/SprogTaskEntrance.vue";
import Sprites from "@/components/Sprites.vue";
import CollectCardPup from "@/components/CollectCardPup.vue";
import PumpkinPup from "@/components/PumpkinPup.vue";
import CollectHint from "@/components/CollectHint.vue";
import UserWallet from "@/components/UserWallet.vue";
import ServerTime from "@/components/ServerTime.vue";
import { controlRequest } from "@/api";
import { sendInstructions, asyncSendInstructions } from "@/api/sendData";
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { decodeBase64String } from "@/utils/utils";
import { showToast } from "vant";
import { useToggle } from "@vant/use";
import {
  ref,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  watch,
  computed,
  onMounted,
} from "vue";

import upcoin_1 from "@/assets/images/control/upcoin_1.png";
import upcoin_1_active from "@/assets/images/control/upcoin_1_active.png";
import upcoin_10 from "@/assets/images/control/upcoin_10.png";
import upcoin_10_active from "@/assets/images/control/upcoin_10_active.png";

import upcoin_auto from "@/assets/images/control/upcoin_auto.png";
import upcoin_auto_active from "@/assets/images/control/upcoin_auto_active.png";

const router = useRouter();
const store = useStore();

const super_gold = store.imgBaseUrl + "/mxt/super_gold.gif";

const count_down_time_1 = store.imgBaseUrl + "/mxt/count_down_time_1.png";
const count_down_time_2 = store.imgBaseUrl + "/mxt/count_down_time_2.png";
const count_down_time_3 = store.imgBaseUrl + "/mxt/count_down_time_3.png";
const count_down_time_4 = store.imgBaseUrl + "/mxt/count_down_time_4.png";
const count_down_time_5 = store.imgBaseUrl + "/mxt/count_down_time_5.png";
const count_down_time_6 = store.imgBaseUrl + "/mxt/count_down_time_6.png";

const ticket_icon = store.imgBaseUrl + "/ticket.png";
const head_phone = store.imgBaseUrl + "/head_phone.png";

// 坐下回调
const handleSeatCallBack = () => {
  mxtNoOperationBackCountStart();
};

//#region 播放器
const _Player = ref(null);
const isReady = ref(false);
let Onlookers = false;
const readyChange = (statu) => {
  isReady.value = statu;
  if (statu && !Onlookers) {
    Onlookers = true;
    sendInstructions(
      {
        msg_type: "enter_machine",
        game_name: store.current_machine.product_name,
        machine_index: store.current_machine.machine_index,
      },
      100
    );
  }
};

const handle_update_player = () => {
  _Player.value?.handlePlayUpdata();
};

onMounted(() => {});
//#endregion

const count_down_time_arr = [
  count_down_time_1,
  count_down_time_2,
  count_down_time_3,
  count_down_time_4,
  count_down_time_5,
  count_down_time_6,
];

//#region 菜单
const menud = ref();
// 声音
const menuSoundClick = (statu) => {
  _Player.value && _Player.value.videoMuted(!statu);
};
// 退出

// 退分/退出观战 协议
const exitGameRequest = (type = "visit") => {
  const backType = type;

  // 退出观战
  if (backType == "visit") {
    const leave_params = {
      msg_type: "leave_machine",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
    };
    sendInstructions(leave_params, 0);
    if (store.channelType == "ios") {
      sendInstructions(leave_params, 50);
      sendInstructions(leave_params, 100);
    }
  }
  // 退分
  if (backType == "exit") {
    // 退分 params
    const params = {
      msg_type: "return_lottery",
      game_name: store.current_machine.product_name,
      machine_index: store.current_machine.machine_index,
      device_name: store.current_device.device_name,
    };

    // 第一次
    sendInstructions(params, 0);
    // 第二次
    sendInstructions(params, 1000);
    // 第三次
    sendInstructions(params, 1800);
  }
};

// 退出游戏重置
const exitRoom = () => {
  store.initStatu();
  router.replace({
    name: "home",
  });
};

//#endregion

// 退出事件
const handleExitEvent = () => {
  // 观战直接退
  if (store.gameIsWatch) {
    isReady.value && exitGameRequest();
    router.replace("/home");

    return;
  }

  // 结算退出
  exitGameRequest("exit");
  if (store.mxtHintDataNumberCount > 0) {
    menud.value && menud.value.exitPupShowToggle(false);
    window.set_data({
      msg_type: "return_lottery_result",
      info: {
        lottery: store.mxtHintDataNumberCount,
        game_name: store.current_machine.product_name,
        machine_index: store.current_machine.machine_index,
        device_name: store.current_device.device_name,
      },
    });
  } else exitRoom();
};

//#endgion

const mxt_funtion_btns = reactive({
  coin: false,
  coin2: false,
  autoCoin: false,
  wiper: false,
});

// 马戏团无操作返回
const mxtNoOperationBackCount = ref(90);
const MxtBackTime = 90; // 无操作的时间

// 雨刷

const handleMxtWiperStart = (event) => {
  console.log("event", event);

  const target = event.target;
  target.style.transform = `scale(${1.2})`;
};

const handleMxtWiperEnd = (evt) => {
  if (mxt_funtion_btns.wiper) return;

  const target = evt.target;
  target.style.transform = `scale(${1})`;

  mxt_funtion_btns.wiper = true;
  const params = {
    device: store.current_device.device_name,
    product_id: store.current_machine.product_id,
    channel: 5,
    type: 2,
    times: 1,
    width: 50,
    interval: 100,
    uid: parseInt(store.userInfo.uid),
  };

  setTimeout(() => {
    mxt_funtion_btns.wiper = false;
  }, 2100);

  controlRequest(params);
};

//#region 投币
const coin_status = reactive({
  l: false, // left 1
  a: false, // auto
  r: false, // right 10
});

// 固定次數投幣
const coin_up_num = async (num) => {
  const _key = num == 1 ? "l" : "r";
  const _sTime = num == 1 ? 1e3 : 3e3;
  if (num == 1 && coin_status[_key]) return;
  if (num == 10 && coin_status[_key]) return;

  coin_status[_key] = true;
  coin_status.a = false;
  await coin_up_request(num);
  mxtNoOperationBackCountReset();
  setTimeout(() => {
    coin_status[_key] = false;
  }, _sTime);
};

// 投币请求
let coin_timer = null;
const coin_up_request = async (num) => {
  const params = {
    msg_type: "up_coin",
    machine_index: store.current_machine.machine_index,
    game_name: store.current_machine.product_name,
    device_name: store.current_device.device_name,
    up_num: num,
  };

  // 幣量不足
  if (
    Number(store.userInfo.gold) <
    Number(store.current_machine.up_coin) * num
  ) {
    coin_status.a = false;
    showToast({
      type: "fail",
      message: "币量不足\n請先充值",
      onClose() {
        // store.payPupToggle(true);
      },
    });
    return;
  }

  if (store.await_up_coin_result) return;

  store.$patch((state) => {
    state.await_up_coin_result = true;
  });

  coin_timer && clearTimeout(coin_timer);
  coin_timer = setTimeout(() => {
    store.await_up_coin_result && (store.await_up_coin_result = false);
  }, 5000);

  sendInstructions(params);

  // sendInstructions(params);
  // try {
  //   const res = await asyncSendInstructions(params);
  //   if (res.error === "ok") {
  //     store.$patch((state) => {
  //       state.userInfo.gold =
  //         Number(state.userInfo.gold) - Number(res.dec_gold);
  //     });
  //   } else {
  //     coin_status.a = false;
  //     showToast({
  //       type: "fail",
  //       message: "请稍后重试",
  //     });
  //   }
  // } catch (error) {
  //   sendInstructions(params);
  //   // coin_status.a = false;
  //   // showToast({
  //   //   type: "fail",
  //   //   message: "请稍后重试",
  //   // });
  // }
  // 自动投币
  if (coin_status.a) {
    setTimeout(() => {
      coin_up_request(autoSpeed.value);
    }, 15e2);
  }
};

// 自动
const [setAutoSpeedValueShow, setAutoSpeedValueShowToggle] = useToggle(false);
const autoSpeed = ref(1);
const handle_auto_btn_click = () => {
  if (coin_status.a) {
    coin_status.a = false;
  } else {
    setAutoSpeedValueShowToggle(true);
  }
};

// 控制进度  自动投币的进度条
const s_block = ref();
const s_active = ref();
const bar_wrappper = ref();
const speed_item_d = ref();
const controlProgress = (index) => {
  if (!s_block.value || !s_active.value || !bar_wrappper.value) return;
  let _w = 0;
  if (index == 1) _w = 0;
  if (index == 2) _w = 50;
  if (index == 3) _w = 100;

  s_active.value.style.width = `${_w}%`;

  autoSpeed.value = index;
};
let isCorrectTarget = false;
let silderStartX = 0;
let silderEndX = 0;
const handleSliderTouchStart = (evt) => {
  const target = evt.target;
  isCorrectTarget =
    target.classList.contains("slider_block") ||
    target.className.indexOf("slider_block") > -1;

  if (
    speed_item_d.value &&
    (evt.target.classList.contains("slider_active_bar") ||
      target.className.indexOf("slider_active_bar") > -1)
  ) {
    const cX = evt.touches[0].clientX;
    speed_item_d.value.some((dom, domIndex) => {
      const { x, width } = dom.getBoundingClientRect();
      const sX = x;
      const eX = sX + width;
      if (cX >= sX && cX < eX) {
        controlProgress(domIndex + 1);
        return true;
      } else return false;
    });
  }

  if (!isCorrectTarget) return;
  silderStartX = evt.touches[0].clientX;
};
const handleSliderTouchMove = (evt) => {
  if (!isCorrectTarget) return;
  silderEndX = evt.touches[0].clientX - silderStartX;
};
const handleSliderTouchEnd = (evt) => {
  if (!isCorrectTarget) return;

  if (silderEndX > 25) {
    controlProgress(autoSpeed.value == 1 ? 2 : 3);
  } else if (silderEndX < -25) {
    controlProgress(autoSpeed.value == 3 ? 2 : 1);
  }
};

// 确认开启自动
const confirmStartAuto = () => {
  setAutoSpeedValueShowToggle(false);
  coin_status.a = true;
  coin_up_request(autoSpeed.value);
};

watch(
  () => coin_status.a,
  (newV) => {
    if (newV) {
      mxtNoOperationBackCountReset();
      mxtNoOperationBackCountEnd();
    } else mxtNoOperationBackCountStart();
  }
);

//#endregion

// 监听马戏团退票信息

watch(
  () => store.mxtHintDataList.length,
  (newV) => {
    mxtNoOperationBackCount.value < MxtBackTime &&
      mxtNoOperationBackCountReset();
    // 清除多余数据 虚拟DOM
    if (newV > 10) {
      store.$patch((state) => {
        state.mxtHintDataList = [state.mxtHintDataList[9]];
      });
    }
  }
);

const currentRate = ref(100);

let mxtNoOperationBackTimer = null;

const mxtNoOperationBackCountStart = () => {
  mxtNoOperationBackCountEnd();
  mxtNoOperationBackTimer = setInterval(() => {
    mxtNoOperationBackCount.value = mxtNoOperationBackCount.value - 1;
    currentRate.value = parseInt(
      (mxtNoOperationBackCount.value / MxtBackTime) * 100
    );
    if (mxtNoOperationBackCount.value - 1 < 0) {
      handleExitEvent();
      mxtNoOperationBackCountEnd();
    }
  }, 1000);
};

const mxtNoOperationBackCountReset = () => {
  mxtNoOperationBackCount.value = MxtBackTime;
};
const mxtNoOperationBackCountEnd = () => {
  mxtNoOperationBackTimer != null && clearInterval(mxtNoOperationBackTimer);
};

watch(
  () => store.gameIsWatch,
  (newV) => {
    if (!newV) {
      mxtNoOperationBackCountStart();
    }
  }
);

//#region 每日推币任务
const taskPupShow = ref(false);

const taskPupShowChange = (evt, statu) => {
  evt.stopPropagation && evt.stopPropagation();
  evt.preventDefault && evt.preventDefault();
  if (!isReady.value) return;

  statu && store.getGameViewTaskData();
  taskPupShow.value = statu;
};

const getGameViewTaskDone = (index) => {
  sendInstructions(
    {
      msg_type: "get_player_game_task_reward",
      game_name: store.current_machine.product_name,
      index,
    },
    0
  );
};

//#endregion

onBeforeMount(() => {
  store.getGameViewTaskData();
});

onBeforeUnmount(() => {
  mxtNoOperationBackCountEnd();
  if (coin_status.a) {
    coin_status.a = false;
  }
});

// 全局点击
const golbalClickEvent = (evt) => {
  evt.stopPropagation && evt.stopPropagation();
  evt.preventDefault && evt.preventDefault();
  // mxtNoOperationBackCountReset();

  menud.value && menud.value.menuOpenStatu && menud.value.menuStatuToggle(evt);
  // taskPupShow.value && taskPupShowChange(false);
};

//#region 魔术连线数据同步
let asyncDataTimer = -1;

const lineData = reactive({
  line_reward_data: {
    quanpan: 0,
    jp1: 0,
    jp2: 0,
    jp3: 0,
  },
  loacltions: [
    {
      head: 0,
    },
    {
      head: 0,
    },
    {
      head: 0,
    },
  ],
});

const asyncLineData = async () => {
  const res = await asyncSendInstructions({
    msg_type: "get_machine_info_by_group",
    group: "msqy_line",
  });

  try {
    const arr = res.filter((item) => {
      return item.line_reward_index == store.current_machine.line_reward_index;
    });
    if (arr.length > 0) {
      lineData.line_reward_data.jp1 = arr[0].line_reward_data.jp1;
      lineData.line_reward_data.jp2 = arr[0].line_reward_data.jp2;
      lineData.line_reward_data.jp3 = arr[0].line_reward_data.jp3;
      lineData.line_reward_data.quanpan = arr[0].line_reward_data.quanpan;

      arr.forEach((it, ii) => {
        if (it.device_info[0].occupy.player_id) {
          lineData.loacltions[ii].head =
            it.device_info[0].occupy.head ||
            it.device_info[0].occupy.head_frame;
        } else lineData.loacltions[ii].head = 0;
      });
    }
  } catch (error) {
    console.log("errrrrr", error);
  }

  if (asyncDataTimer != 0) {
    asyncDataTimer = setTimeout(() => {
      asyncLineData();
    }, 1e3);
  }
};
onMounted(() => {
  setTimeout(() => {
    asyncLineData();
  }, 1500);
});

onBeforeUnmount(() => {
  asyncDataTimer = 0;
});

//#endregion

//#region 部分ui控制
const ui_control_show = ref(true);
let ui_flag = false;
const ui_control_show_toggle = () => {
  if (ui_flag) return;
  ui_flag = true;

  ui_control_show.value = !ui_control_show.value;
  setTimeout(() => {
    ui_flag = false;
  }, 5e2);
};

//#endregion

//#region 收集识别
const [collectCardShow, collectCardShowToggle] = useToggle(false);
const [rankPupShow, rankPupShowToggle] = useToggle(false);
//#endregion
</script>

<style lang="scss" scoped>
.mxt_view_wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .occupy_info_wrapper {
    position: absolute;
    top: 50px;
    left: 15px;
    background-color: rgba($color: #000, $alpha: 0.4);
    border-radius: 40px;
    display: flex;
    .user_portrait {
      width: 50px;
      height: 50px;
    }
    .user_name {
      //   width: ;
      line-height: 50px;
      padding: 0 10px;
    }
  }
}

.wallet_wrapper {
  position: absolute;
  left: 15px;
  top: 140px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top_info_wrapper {
  position: absolute;
  top: 35px;
  left: 16px;
  display: flex;
  .user_info_wrapper {
    display: flex;
    background-color: rgba($color: #000, $alpha: 0.4);
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    width: 168px;
    padding: 0 !important;
    height: 40px;
    .user_portrait {
      width: 40px;
      height: 40px;
      position: relative;
      // left: -4px;
      top: 0;
      // margin-right: 6px;
      .head {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
      }
    }

    .user_name_wrapper {
      width: 66px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name_key {
        font-size: 14px;
        font-weight: bold;
        text-wrap: nowrap;
        max-width: 66px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .uid_key {
        font-size: 12px;
        color: rgba($color: #fff, $alpha: 0.6);
      }
    }

    .user_grade_wrapper {
      position: relative;
      // width: 140px;
      // height: 40px;

      .user_grade_content {
        display: flex;
        flex-direction: column;
        // height: 80px;
        height: 100%;
        justify-content: space-around;
      }
    }
  }

  .line_localtion {
    margin-left: 12px;
    width: 110px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    .line_localtion_item {
      position: relative;
      width: 32px;
      height: 36px;

      .head_cover {
        width: 32px;
        height: 32px;
      }

      .location_p {
        position: absolute;
        z-index: 3;
        bottom: 0;
        width: 100%;
        height: 12px;
        line-height: 12px;
        font-size: 10px;
        background-color: rgba($color: #000000, $alpha: 0.5);
        border-radius: 6px;
        text-align: center;
      }
    }
  }
}

.line_reward_block {
  position: absolute;
  top: 210px;
  left: 16px;
  width: 50px;
  height: 174px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/msqy_line_room_block.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 3;
  padding: 14px 0;
  .line_reward_content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .line_reward_data_item {
      text-align: center;
      width: 32px;
      height: 32px;

      .line_reward_data_item_name {
        font-weight: bold;
      }

      .line_reward_data_item_value {
        position: relative;
        top: -3px;
        font-size: 11px;
        text-shadow: 1px 1px 0px #0b0004;
      }
    }
  }

  .lighting_1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/room_light_1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    animation-name: lighting;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
  }

  .lighting_2 {
    @extend .lighting_1;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/room_light_2.png");
    animation-direction: alternate;
  }
}

// 测试元素
.test_box {
  width: 100%;
  padding: 10px;
  position: absolute;
  left: 0;
  bottom: 120px;
  color: 14px;
  text-align: center;
  background-color: rgba($color: #000, $alpha: 0.4);
  display: flex;
  justify-content: center;

  .btne {
    padding-left: 10px;
  }
}

.tbj_control_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 4;
  display: flex;
  padding: 0 12px;
  justify-content: space-between;
  .num_coin_btn {
    width: 85px;
    height: 50px;
  }
  .auto_coin_btn {
    width: 156px;
    height: 66px;
  }
}

.logout_wrapper {
  width: 90vw;
  padding-top: 20px;
  padding-bottom: 20px;
  // height: 200px;
  background: linear-gradient(180deg, #bbd8f9 0%, #ffffff 58%, #eef6f9 100%);
  text-align: center;

  .logout_title {
    font-size: 22px;
    background: linear-gradient(90deg, #3967ff 0%, #7091ff 44%, #8c85ff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 1000;
  }

  .logout_content {
    margin-top: 20px;

    font-size: 16px;
    margin-bottom: 34px;
    padding: 0 26px;

    .number_s {
      width: 100%;
      display: flex;
      .number_item {
        flex: 1;
        color: #6843ff;
        font-size: 14px;
        font-weight: 1000;
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(3) {
          text-align: right;
        }
      }
    }

    .number_bar_wrapper {
      position: relative;
      margin-top: 10px;
      width: 100%;
      height: 14px;
      border-radius: 8px;
      background-color: #c8c8fd;
      box-shadow: 0px 0px 4px 1px rgba(41, 9, 172, 0.4);
      // overflow: hidden;

      .slider_active_bar {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 8px;
        width: 0%;
        background-color: #6843ff;
        z-index: 10;
        transition: width 0.5s;
        .slider_block {
          background-color: #d977ff;
          width: 22px;
          height: 22px;
          position: absolute;
          right: 0;
          transform: translateX(50%);
          top: -4px;
          transition: transform 0.4s;
          z-index: 5;
          border-radius: 12px;
        }
      }

      .speed_wrapper {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        display: flex;
        overflow: hidden;
        .speed_item {
          flex: 1;
          // &:nth-child(1) {
          //   // background-color: red;
          // }
          // &:nth-child(2) {
          //   // background-color: skyblue;
          // }
          // &:nth-child(3) {
          //   // background-color: orange;
          // }
        }
      }
    }
  }

  .logout_btns {
    display: flex;
    justify-content: center;
    .logout_btn {
      width: 40%;
      height: 40px;
      background: linear-gradient(180deg, #81c3ff 0%, #5034ff 100%);
      margin-right: 10px;
      line-height: 40px;
      border-radius: 20px;
    }
  }
}

.super_lottery_hint {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 300px;
  z-index: 5;
}

.mxt_hint_wrapper {
  width: 40vw;
  height: 320px;
  position: absolute;
  left: 0;
  bottom: 120px;

  z-index: 3;

  .mxt_hint_item {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0) scale(1);
    display: inline-block;
    color: #fff;
    font-weight: 800;
    font-size: 28px;
    padding: 4px 0px 4px 32px;
    opacity: 0;
    text-shadow: -1px 2px 0px rgba(20, 0, 38, 0.1);
    background: linear-gradient(90deg, #fea431 0%, #fee99e 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: mxt_hint 4s 1;
    // animation-duration: 3s;
    animation-timing-function: ease-in;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
      background-size: 100% 100%;
    }
  }
}

.operation_timer_back_wrapper {
  position: absolute;
  right: 20px;
  bottom: 132px;
  z-index: 3;
  width: 52px;
  height: 52px;
  padding: 4px;
  // background-color: orange;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/operation_timer_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 50%;

  .operation_timer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    font-size: 16px;
    font-weight: bold;

    .timer_number {
      display: inline-block;
      background: radial-gradient(ellipse, #f5f35b, #f2a03f);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      text-shadow: 2px 2px 4px rgba($color: #9e233f, $alpha: 0.6);
      // text-stroke: 2px #ff0000;
      // text-stroke: 2px #f5f35b;
      // -webkit-text-stroke: 2px #f5f35b;
    }
  }

  .operation_timer_bg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    height: 80%;
    background-color: #9843e5;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 1;
  }
}

// 倒计时
.count_down_time_wrapper {
  position: absolute;
  left: 50%;
  top: 42%;
  width: 190px;
  height: 190px;
  transform: translate(-50%, -50%);
  z-index: 3;
  .count_down_time_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

// 围观者
.visits_list_wrapper {
  // display: flex;
  z-index: 4;
  position: absolute;
  top: 92px;
  right: 15px;
  // width: 110px;
  height: 26px;
  padding: 0;
  margin: 0;
  display: flex;

  .visits_text_wrapper {
    height: 100%;
    line-height: 26px;
  }
  .visit_item_user_header {
    width: 26px;
    height: 100%;
    border-radius: 50%;
  }
}

// 推币任务
.norem_task_icon_wrapper {
  position: absolute;
  right: 15px;
  top: 140px;
  width: 52px;
  height: 52px;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/sprites.png");
  background-size: auto 52px;

  z-index: 6;
  .task_hot_icon {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 14px;
    height: 14px;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    background-color: #fc0000;
    border-radius: 50%;
  }

  .task_icon {
    width: 34px;
    height: 34px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/task_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    //
  }

  .task_icon_animation {
    // animation: task_hint_shake 0.3s infinite;
    animation-name: task_hint_shake;

    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    // animation-direction: alternate;
    // animation-play-state: running;
  }

  .task_icon_name {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // transform: translate(-5%, 110%);
    // width: 110%;
    height: 14px;
    line-height: 14px;
    font-size: 8px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0px 0px 1px 1px #7d22c6;
    border-radius: 10px;
    white-space: nowrap;
    text-wrap: nowrap;
    // border: 1px solid;
    // border-image: linear-gradient(
    //     180deg,
    //     rgba(215, 91, 192, 1),
    //     rgba(141, 89, 223, 1)
    //   )
    //   1 1;
  }
}

.norem_wiper_btn_wrapper {
  position: absolute;
  top: 390px;
  right: 15px;
  width: 52px;
  height: 52px;
  z-index: 2;
  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/wiper_icon2.png");
  background-size: auto 52px;
  background-repeat: no-repeat;
}

.task_content_wrapper {
  position: absolute;
  right: 15px;
  top: 21.5892vh;
  width: 224px;
  z-index: 6;

  .task_head_title {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background: linear-gradient(79deg, #ffb757 0%, #fed6a2 100%);
    height: 24px;
    line-height: 24px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);

    padding-left: 42px;

    position: relative;

    .task_title_icon {
      position: absolute;
      top: -10px;
      left: 8px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/mxt/task_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 34px;
      height: 34px;
    }
    .task_back_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      background-image: url("@/assets/images/task_pup_back_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
    }
  }

  .task_container {
    background-color: rgba($color: #502cb5, $alpha: 0.6);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;

    .task_all_wrapper {
      background-color: #6a66de;
      height: 34px;
      line-height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 6px;
      .task_all_task_reward {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fee22a;
        .reward_icon {
          width: 16px;
          height: 16px;
        }
      }

      .task_all_task_get {
        width: 66px;
        height: 20px;
        border-radius: 10px;
        background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
        color: #280923;
        font-size: 12px;
        text-shadow: none;
        height: 20px;
        text-align: center;
        line-height: 20px;
      }
      .task_all_task_done {
        color: #fee22a;
      }
    }

    .task_list_wrapper {
      // max-height: 276px;
      // overflow-y: auto;
      // overflow: auto;
      padding: 0 4px;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);
      &::-webkit-scrollbar {
        width: 0;
      }
      .task_list_item {
        display: flex;
        background-color: rgba($color: #4f5b90, $alpha: 0.8);
        padding: 8px 0;

        margin-top: 6px;

        .task_name {
          width: 50px;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .task_reward {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: start;
          color: #fee22a;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 5px;
          .reward_icon {
            width: 16px;
            height: 16px;
            margin: 0 2px;
          }
        }

        .task_btn_wrapper {
          width: 66px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          .task_num {
            background-color: #5048a3;
            border-radius: 6px;
            height: 20px;
          }

          .task_get_btn {
            border-radius: 10px;
            background: linear-gradient(90deg, #ffd884 0%, #ffc018 100%);
            color: #280923;
            font-size: 12px;
            text-shadow: none;
            height: 20px;
          }

          .task_done {
            height: 20px;
            color: #fee22a;
          }
        }
      }

      .task_footer {
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}

@keyframes task_hint_shake {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes task_hint_shake {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes wiper_active_animation {
  0% {
    transform: translateX(-50%) rotate(-30deg);
  }

  50% {
    transform: translateX(-50%) rotate(30deg);
  }

  100% {
    transform: translateX(-50%) rotate(-30deg);
  }
}

@-webkit-keyframes wiper_active_animation

/* Safari 与 Chrome */ {
  0% {
    transform: translateX(-50%) rotate(-30deg);
  }

  50% {
    transform: translateX(-50%) rotate(30deg);
  }

  100% {
    transform: translateX(-50%) rotate(-30deg);
  }
}

@keyframes mxt_hint {
  0% {
    transform: translate(-50%, 0) scale(1);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }

  20% {
    transform: translate(-50%, -100%) scale(1);
  }

  35% {
    transform: translate(-50%, -210%) scale(1.4);
  }

  50% {
    transform: translate(-50%, -300%) scale(1);
    opacity: 0.8;
  }

  100% {
    transform: translate(-50%, -600%) scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes mxt_hint

/* Safari 与 Chrome */ {
  0% {
    transform: translate(-50%, 0) scale(1);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }

  20% {
    transform: translate(-50%, -100%) scale(1);
  }

  35% {
    transform: translate(-50%, -210%) scale(1.4);
  }

  50% {
    transform: translate(-50%, -300%) scale(1);
    opacity: 0.8;
  }

  100% {
    transform: translate(-50%, -600%) scale(1);
    opacity: 0;
  }
}

@keyframes lighting {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes lighting

/* Safari 与 Chrome */ {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
