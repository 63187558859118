<template>
  <van-popup
    v-model:show="props.pupshow"
    :close-on-click-overlay="false"
    @open="pupOpenCallBack"
    :style="pupStyle"
  >
    <template v-if="!props.islandscape">
      <div class="pumpkin_rank_wrapper">
        <div class="top_image_wrapper">
          <van-image
            width="100%"
            height="auto"
            fit="center"
            :src="mxt_rank_title_img"
            :show-loading="false"
            v-if="store.current_machine.product_name == 'mxt'"
          />
          <van-image
            width="100%"
            height="auto"
            fit="center"
            :show-loading="false"
            :src="msqy_rank_title_img"
            v-if="store.current_machine.product_name == 'msqy'"
          />
          <van-image
            width="100%"
            height="auto"
            fit="center"
            :src="mgc_rank_title_img"
            :show-loading="false"
            v-if="store.current_machine.product_name == 'mgc'"
          />
          <van-image
            width="100%"
            height="auto"
            fit="center"
            :src="bbl_rank_title_img"
            :show-loading="false"
            v-if="store.current_machine.product_name == 'bbl'"
          />
          <van-image
            width="100%"
            height="auto"
            fit="center"
            :src="hw3_rank_title_img"
            :show-loading="false"
            v-if="store.current_machine.product_name == 'hw3'"
          />
        </div>
        <div class="rank_list_wrapper">
          <div class="pup_close_icon" @click="closePumpkinEvent"></div>

          <div class="rank_title_wrapper">
            <div class="rank">排名</div>
            <div class="player_info">玩家</div>
            <div class="reward">奖励</div>
          </div>

          <div class="rank_list_content_wrapper">
            <div
              class="rank_list_item"
              v-for="(rankItem, rankIndex) in store.pumpkinRankList"
              :key="rankItem.uid"
            >
              <div class="rank">
                <div class="rank_icon" v-if="rankIndex <= 2">
                  <van-image
                    width="100%"
                    height="auto"
                    fit="fill"
                    :show-loading="false"
                    :src="
                      rankIndex == 0
                        ? placing1
                        : rankIndex == 1
                        ? placing2
                        : placing3
                    "
                  />
                </div>
                <div class="rank_text" v-else>
                  {{ rankIndex + 1 }}
                </div>
              </div>

              <div class="player_info">
                <div class="player_info_content">
                  <div class="player_info_head">
                    <HeadPortrait
                      :level="Number(rankItem.recharge_level)"
                      :width="'100%'"
                      :height="'100%'"
                      :src="rankItem.head ? rankItem.head : rankItem.head_frame"
                      :ae_id="rankItem.select_title"
                      ae
                      fe
                    />
                  </div>
                  <div class="player_info_name_and_grade">
                    <div class="user_name">
                      <van-text-ellipsis
                        :content="decodeBase64String(rankItem.player_name)"
                      />
                    </div>
                    <div class="grade">
                      <Level
                        :level_value="
                          rankItem.level ? Number(rankItem.level) : 1
                        "
                        :border="false"
                        :background="rankIndex % 2 == 0 ? '#CF9D67' : '#F7E1BD'"
                        :color="rankIndex % 2 == 0 ? '#FFFFFF' : '#D09E68'"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="reward">
                <div
                  class="score"
                  :style="{
                    paddingRight:
                      store.current_machine.product_name == 'mgc'
                        ? '36px'
                        : '20px',
                  }"
                >
                  {{ rankItem.num }}

                  <div
                    class="nanguaiconaaa"
                    v-if="store.current_machine.product_name == 'mgc'"
                  ></div>
                </div>
                <div
                  class="reward_value"
                  :style="{
                    marginTop:
                      store.current_machine.product_name == 'mgc' ? '8px' : '0',
                  }"
                  v-if="
                    store.collect_reward_list[
                      store.current_machine.product_name
                    ][rankIndex]
                  "
                >
                  +{{
                    store.collect_reward_list[
                      store.current_machine.product_name
                    ][rankIndex]
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="rank_bottom_icon_left"></div>
        <div class="rank_bottom_icon_right"></div></div
    ></template>

    <template v-else>
      <div class="landscape_pumpkin_rank_wrapper">
        <div class="left_title_img_wrapper">
          <div class="left_title_img width absolute-center">
            <van-image
              width="100%"
              height="auto"
              fit="center"
              :show-loading="false"
              :src="mgc_rank_title_img"
              v-if="store.current_machine.product_name == 'mgc'"
            />
            <van-image
              width="100%"
              height="auto"
              fit="center"
              :show-loading="false"
              :src="bbl_rank_title_img"
              v-if="store.current_machine.product_name == 'bbl'"
            />
            <van-image
              width="100%"
              height="auto"
              fit="center"
              :show-loading="false"
              :src="hw3_rank_title_img"
              v-if="store.current_machine.product_name == 'hw3'"
            />
          </div>
        </div>
        <div class="rank_list_wrapper">
          <div class="pup_close_icon" @click="closePumpkinEvent"></div>
          <div class="rank_bottom_icon_left"></div>
          <div class="rank_bottom_icon_right"></div>

          <div class="rank_list_content_wrapper">
            <div class="rank_title_wrapper">
              <div class="rank">排名</div>
              <div class="player_info">玩家</div>
              <div class="reward">奖励</div>
            </div>

            <div class="rank_list_content">
              <div class="rank_list">
                <div
                  class="rank_list_item"
                  v-for="(rankItem, rankIndex) in store.pumpkinRankList"
                >
                  <div class="rank">
                    <div class="rank_icon" v-if="rankIndex <= 2">
                      <van-image
                        width="100%"
                        height="auto"
                        fit="fill"
                        :show-loading="false"
                        :src="
                          rankIndex == 0
                            ? placing1
                            : rankIndex == 1
                            ? placing2
                            : placing3
                        "
                      />
                    </div>
                    <div class="rank_text" v-else>
                      {{ rankIndex + 1 }}
                    </div>
                  </div>

                  <div class="player_info">
                    <div class="player_info_content">
                      <div class="player_info_head">
                        <HeadPortrait
                          :level="Number(rankItem.recharge_level)"
                          :width="'100%'"
                          :height="'100%'"
                          :src="
                            rankItem.head ? rankItem.head : rankItem.head_frame
                          "
                          :ae_id="rankItem.select_title"
                          ae
                          fe
                        />
                      </div>
                      <div class="player_info_name_and_grade">
                        <div class="user_name">
                          <van-text-ellipsis
                            :content="decodeBase64String(rankItem.player_name)"
                          />
                        </div>
                        <div class="grade">
                          <Level
                            :level_value="
                              rankItem.level ? Number(rankItem.level) : 1
                            "
                            :border="false"
                            :background="
                              rankIndex % 2 == 0 ? '#CF9D67' : '#F7E1BD'
                            "
                            :color="rankIndex % 2 == 0 ? '#FFFFFF' : '#D09E68'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="reward">
                    <div
                      class="score"
                      :style="{
                        paddingRight:
                          store.current_machine.product_name == 'mgc'
                            ? '36px'
                            : '10px',
                      }"
                    >
                      {{ rankItem.num }}

                      <div
                        class="nanguaiconaaa"
                        v-if="store.current_machine.product_name == 'mgc'"
                      ></div>
                    </div>
                    <div
                      class="reward_value"
                      :style="{
                        marginTop:
                          store.current_machine.product_name == 'mgc'
                            ? '8px'
                            : '0',
                      }"
                    >
                      +{{
                        store.collect_reward_list[
                          store.current_machine.product_name
                        ][rankIndex]
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </van-popup>
</template>

<script setup>
import { useStore } from "@/store/store";
import { watch, reactive, onBeforeMount } from "vue";
import { decodeBase64String } from "@/utils/utils";
import { sendInstructions } from "@/api/sendData";

import HeadPortrait from "@/components/HeadPortrait.vue";
import Level from "@/components/Level.vue";
const store = useStore();

const mgc_rank_title_img = store.imgBaseUrl + "/game/mgc_rank_title_img.png";

const bbl_rank_title_img = store.imgBaseUrl + "/bbl/bbl_rank_title_img.png";

const mxt_rank_title_img = store.imgBaseUrl + "/mxt/mxt_rank_title_img.png";

const hw3_rank_title_img = store.imgBaseUrl + "/hw/hw_rank_title_img.png";

const msqy_rank_title_img = store.imgBaseUrl + "/mxt/msqy_rank_title_img.png";

const placing1 = store.imgBaseUrl + "/rank/placing1.png";
const placing2 = store.imgBaseUrl + "/rank/placing2.png";
const placing3 = store.imgBaseUrl + "/rank/placing3.png";

const emits = defineEmits(["closeIconClick"]);

const props = defineProps({
  pupshow: {
    type: Boolean,
    default() {
      return false;
    },
  },
  islandscape: {
    type: Boolean,
    default() {
      return false;
    },
  },
});

const pupStyle = props.islandscape
  ? {
      height: "82vw",
      width: "80vh",
      maxWidth: "80vh",
      background: "none",
      paddingTop: "0px",
      transform: "translateX(-20%) translateY(-50%) rotate(90deg)",
      transformOrigin: "center center",
    }
  : {
      height: "auto",
      width: "100vw",
      maxWidth: "100vw",
      background: "none",
      paddingTop: "156px",
    };

const closePumpkinEvent = () => {
  emits("closeIconClick");
};

const pupOpenCallBack = () => {
  const key = store.current_machine.product_name;

  store.pumpkinRankList = [];

  const params = {
    msg_type: "get_nangua_rank",
    type: key == "mgc" ? "nangua" : key,
  };

  store.collect_reward_list[key].length == 0 &&
    sendInstructions({ msg_type: "get_player_collect" }, 0);

  sendInstructions(params, 50);
};
</script>

<style lang="scss" scoped>
.pumpkin_rank_wrapper {
  width: 100%;
  min-height: 300px;
  padding: 0 10px 32px 10px;
  position: relative;

  .rank_bottom_icon_left {
    position: absolute;
    bottom: 0;
    left: -6px;
    width: 60px;
    height: 60px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_bottom_left_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .rank_bottom_icon_right {
    position: absolute;
    bottom: 0;
    right: -6px;
    width: 60px;
    height: 60px;
    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_bottom_right_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .top_image_wrapper {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -56%);
    width: 240px;
  }

  .rank_list_wrapper {
    width: 100%;
    padding: 110px 18px 18px 18px;
    // border: 2px solid #ffc8a4;
    border-radius: 8px;

    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_day_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .pup_close_icon {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(0px, -10px);
      width: 34px;
      height: 34px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .rank_title_wrapper {
      height: 32px;
      line-height: 32px;
      display: flex;
      text-align: center;
      background: linear-gradient(180deg, #d09e68 0%, #ce9c66 100%);
      border-radius: 20px;
      position: relative;
      .rank {
        width: 50px;
      }
      .player_info {
        flex: 1;
      }
      .reward {
        width: 80px;
      }

      &::after {
        content: "每日 0:00 名次更新";
        position: absolute;
        font-size: 10px;
        left: 50%;
        top: 0;
        color: #fff;
        transform: translate(-50%, -80%);
      }
    }

    .rank_list_content_wrapper {
      margin-top: 10px;
      border-radius: 8px;
      height: 310px;
      overflow: auto;

      .rank_list_item {
        display: flex;
        // min-height: 60px;
        padding: 10px 0;
        .rank {
          position: relative;
          width: 50px;

          .rank_icon {
            width: 20px;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .rank_text {
            width: 20px;
            height: 20px;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;

            background: linear-gradient(180deg, #59cef1 0%, #0a3e8d 100%);

            border: 1px solid #f5deb8;
            text-align: center;
            line-height: 20px;
            font-size: 14px;
          }
        }
        .player_info {
          flex: 1;
          display: flex;
          // flex-direction: column;
          justify-content: start;
          align-items: center;
          padding-left: 28px;
          .player_info_content {
            display: flex;
            flex: 1;
            .player_info_head {
              height: 38px;
              width: 38px;
            }
            .player_info_name_and_grade {
              padding-left: 10px;
              flex: 1;
              .user_name {
                font-size: 12px;
                color: #865534;
              }
              // .user_grade {}
            }
          }
        }
        .reward {
          width: 80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: end;
          // flex-wrap: wrap;
          .score {
            width: 100%;
            position: relative;
            font-size: 12px;
            padding-right: 32px;
            text-align: right;
            font-weight: 800;
            text-wrap: nowrap;

            color: #865534;

            .nanguaiconaaa {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              width: 22px;
              height: 22px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/nangua_icon.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            // &::after {
            //   content: "";
            //   position: absolute;
            //   right: 10px;
            //   top: 50%;
            //   transform: translateY(-50%);
            //   width: 18px;
            //   height: 18px;
            //   background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/ticket.png");
            //   background-size: 100% 100%;
            //   background-repeat: no-repeat;
            // }
          }

          .reward_value {
            width: 100%;
            position: relative;
            font-size: 12px;
            padding-right: 32px;
            text-align: right;
            color: #f9712b;
            font-weight: 800;

            &::after {
              content: "";
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              width: 18px;
              height: 18px;
              background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }
        }

        &:nth-child(odd) {
          background: linear-gradient(180deg, #fcecd3 0%, #f4dcb3 100%);
        }

        &:nth-child(even) {
          background: linear-gradient(180deg, #d09e68 0%, #ce9c66 100%);
        }
      }
    }
  }
}

.landscape_pumpkin_rank_wrapper {
  width: 100%;
  height: 100%;
  padding-right: 30px;
  padding-top: 10px;
  display: flex;
  overflow: hidden;
  // background-color: red;
  .left_title_img_wrapper {
    width: 230px;
    position: relative;

    .left_title_img {
      &::after {
        content: "每日 0:00 名次更新";
        position: absolute;
        font-size: 10px;
        left: 50%;
        bottom: 0;
        color: #fff;
        transform: translate(-50%, 110%);
      }
    }
  }
  .rank_list_wrapper {
    flex: 1;
    padding: 18px 18px 18px 18px;
    // border: 2px solid #ffc8a4;
    border-radius: 8px;

    background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_day_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    // overflow: hidden;

    position: relative;
    .pup_close_icon {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(0px, -10px);
      width: 34px;
      height: 34px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/pup_close2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .rank_bottom_icon_left {
      position: absolute;
      bottom: 0px;
      left: -30px;
      width: 60px;
      height: 60px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_bottom_left_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 12;
    }
    .rank_bottom_icon_right {
      position: absolute;
      bottom: 0px;
      right: -30px;
      width: 60px;
      height: 60px;
      background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/rank_bottom_right_icon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 12;
    }

    .rank_list_content_wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;

      .rank_title_wrapper {
        height: 32px;
        line-height: 32px;
        display: flex;
        text-align: center;
        background: linear-gradient(180deg, #d09e68 0%, #ce9c66 100%);
        border-radius: 20px;
        position: relative;
        .rank {
          width: 50px;
        }
        .player_info {
          flex: 1;
        }
        .reward {
          width: 80px;
        }
      }

      .rank_list_content {
        flex: 1;
        height: calc(100% - 50px);
        background-color: #f9712b;
        overflow: hidden;
        margin-top: 10px;
        border-radius: 12px;

        .rank_list {
          height: 100%;
          overflow: auto;
          .rank_list_item {
            display: flex;
            // min-height: 60px;
            padding: 10px 0;
            .rank {
              position: relative;
              width: 50px;

              .rank_icon {
                width: 20px;
                height: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
              .rank_text {
                width: 20px;
                height: 20px;
                height: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;

                background: linear-gradient(180deg, #59cef1 0%, #0a3e8d 100%);

                border: 1px solid #f5deb8;
                text-align: center;
                line-height: 20px;
                font-size: 14px;
              }
            }
            .player_info {
              flex: 1;
              display: flex;
              // flex-direction: column;
              justify-content: start;
              align-items: center;
              padding-left: 30px;
              .player_info_content {
                display: flex;
                flex: 1;
                .player_info_head {
                  height: 38px;
                  width: 38px;
                }
                .player_info_name_and_grade {
                  padding-left: 10px;
                  flex: 1;
                  .user_name {
                    font-size: 12px;
                    color: #865534;
                  }
                  // .user_grade {}
                }
              }
            }
            .reward {
              width: 80px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              // align-items: end;
              // flex-wrap: wrap;
              .score {
                width: 100%;
                position: relative;
                font-size: 12px;
                text-align: right;
                font-weight: 800;
                text-wrap: nowrap;

                color: #865534;

                .nanguaiconaaa {
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 22px;
                  height: 22px;
                  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/game/nangua_icon.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                }
              }

              .reward_value {
                width: 100%;
                position: relative;
                font-size: 12px;
                padding-right: 32px;
                text-align: right;
                color: #f9712b;
                font-weight: 800;

                &::after {
                  content: "";
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 18px;
                  height: 18px;
                  background-image: url("https://jikejingji.oss-accelerate.aliyuncs.com/appClientStatic/images/gold_icon.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                }
              }
            }

            &:nth-child(odd) {
              background: linear-gradient(180deg, #fcecd3 0%, #f4dcb3 100%);
            }

            &:nth-child(even) {
              background: linear-gradient(180deg, #d09e68 0%, #ce9c66 100%);
            }
          }
        }
      }
    }
  }
}
</style>
